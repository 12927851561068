<template>
  <v-container fluid class="primary-image-bg py-7">
    <v-container>
      <v-row align="center" class="mx-0 py-7">
        <h1 :class="$vuetify.breakpoint.smAndDown ? 'font-weight-regular' : 'display-2 mb-2'" style="width: 100%;">
          {{ $t(`${title}`) }}
        </h1>
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'font-weight-regular' : 'display-1'">
          {{ $t(`${subtitle}`) }}
        </h3>
      </v-row>
      <h6 :class="$vuetify.breakpoint.smAndDown ? 'font-weight-regular' : 'display-1'">
        {{ $t(`${subtitle2}`) }}
      </h6>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'HeroTitle',
  props: {
    backgroundImage: String,
    title: String,
    subtitle: String,
    subtitle2: {
      default: ''
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
