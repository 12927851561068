import { render, staticRenderFns } from "./BiztositasiSzervizPageExternal.vue?vue&type=template&id=5bb8adc8&scoped=true&"
import script from "./BiztositasiSzervizPageExternal.vue?vue&type=script&lang=js&"
export * from "./BiztositasiSzervizPageExternal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bb8adc8",
  null
  
)

export default component.exports