<template>
  <div v-frag>
    <hero-title title="order.orderPlaceNewOrder" subtitle="services.insuranceService"></hero-title>
    <biztositasi-szervizgar-form></biztositasi-szervizgar-form>
  </div>
</template>

<script>
import HeroTitle from 'src/components/titles/HeroTitle';
import BiztositasiSzervizgarForm from 'src/components/forms/BiztositasiSzervizgarForm';

export default {
  name: 'BiztositasiSzervizPageExternal',
  components: {
    HeroTitle, BiztositasiSzervizgarForm,
  },
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
