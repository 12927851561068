<template>
  <div v-frag>
    <div v-show="isFormLoading">
      <loading-component :loading-message="loadingComponentProps.loadingMessage"></loading-component>
    </div>
    <div v-show="!isFormLoading">
      <div v-if="!isUserCanSeeTheForm">
        <user-can-see-the-content></user-can-see-the-content>
      </div>
      <div v-else>
        <v-container class="my-10">
          <v-card>
            <v-stepper v-model="VStepper" alt-labels non-linear>
              <!-- STEPPER HEADER -->
              <v-stepper-header>
                <template v-for="(item, index) in VStepperHeader">
                  <v-stepper-step :key="index"
                                  :step="item.step" :complete="VStepper > index+1" :editable="index < 4 && !requestSuccessSend">
                    {{ $t(`${item.title}`) }}
                  </v-stepper-step>
                  <v-divider v-if="index !== VStepperHeader.length-1"></v-divider>
                </template>
              </v-stepper-header>
              <v-stepper-items>
                <!-- ÁLTALÁNOS INFORMÁCIÓK START -->
                <v-stepper-content step="1">
                  <v-row style="max-height: 660px; overflow-y: scroll;">
                    <v-col cols="12">
                      <div v-html="$t('szervizgar.generalInformation')" ></div>
                    </v-col>
                    <!--<v-col cols="12">
                      <v-divider></v-divider>
                      <v-checkbox v-model="serviceRequestForm.generalInfos"
                                  :label="$t('acceptCheckbox.generalInformation')"
                                  :rules="inputRules.acceptInformation"
                      >
                      </v-checkbox>
                    </v-col>-->
                  </v-row>
                  <v-row justify="end" class="mx-3 mt-5 mb-3">
                    <v-btn class="primary"
                           @click="nextStep">
                      {{ $t('button.next') }}
                    </v-btn>
                  </v-row>
                </v-stepper-content>
                <!-- ÁLTALÁNOS INFORMÁCIÓK END -->
                <!-- KÉSZÜLÉK ADATOK START -->
                <v-stepper-content step="2">
                  <v-form v-model="deviceFormIsValid">
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="serviceRequestForm.serviceRequest.insuranceNumber"
                                      :label="sourceProp === 'mostmobil' ? $t('requestIdOrWorksheetNumber') : $t('damageNumber')"
                                      :rules="inputRules.insuranceNumberRule"
                                      :hint="$t('inputMessages.requiredField')"
                                      class="toUp"
                                      persistent-hint
                                      filled>
                        </v-text-field>
                        <!--v-btn x-small
                               color="primary"
                               @click="setSimLockAndMobilProvider(serviceRequestForm.serviceRequest.insuranceNumber)">
                          Ellenőrzés
                        </v-btn>
                        <div class="text-caption">
                          Eredmény:
                        </div>
                        <div class="text-caption">
                          Hálózati zár: {{ serviceRequestForm.serviceRequest.networkLock }}
                        </div>
                        <div class="text-caption">
                          Mobil provider: {{ serviceRequestForm.serviceRequest.mobilProvider }}
                        </div>
                        <div class="text-caption">
                          AVSid: {{ serviceRequestForm.serviceRequest.availableServiceId }}
                        </div-->
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-file-input v-model="attachment.invoice"
                                      :label="$t('inputLabels.purchaseInvoice')"
                                      :rules="inputRules.maxFileSizeOptional"
                                      :hint="$t('inputMessages.maxFileSizeRuleOptional')"
                                      persistent-hint
                                      accept=".pdf,.PDF,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                                      counter
                                      show-size
                                      :clearable="false"
                                      :append-icon="attachment.invoice ? 'mdi-close' : ''"
                                      @click:append="clearFile('invoice')"
                                      filled>
                          <template v-slot:selection="{text}">
                            <v-chip small label color="primary">
                              {{ text }}
                            </v-chip>
                          </template>
                        </v-file-input>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-file-input v-model="attachment.warrantyCard"
                                      :label="$t('inputLabels.purchaseWarrantyTicket')"
                                      :rules="inputRules.maxFileSizeOptional"
                                      :hint="$t('inputMessages.maxFileSizeRuleOptional')"
                                      persistent-hint
                                      accept=".pdf,.PDF,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                                      counter
                                      show-size
                                      :clearable="false"
                                      :append-icon="attachment.warrantyCard ? 'mdi-close' : ''"
                                      @click:append="clearFile('warrantyCard')"
                                      filled>
                          <template v-slot:selection="{text}">
                            <v-chip small label color="primary">
                              {{ text }}
                            </v-chip>
                          </template>
                        </v-file-input>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete v-model="serviceRequestForm.serviceRequest.brand"
                                        :items="selectFieldOptions.brands"
                                        :label="$t('brand')"
                                        item-value="id"
                                        item-text="name"
                                        :rules="inputRules.generalRules"
                                        :hint="$t('inputMessages.requiredField')"
                                        persistent-hint
                                        filled
                                        return-object
                                        required>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select v-model="serviceRequestForm.serviceRequest.productGroup"
                                  :items="selectFieldOptions.productGroups"
                                  item-text="name"
                                  item-value="id"
                                  :label="$t('inputLabels.productGroup')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  filled
                                  return-object
                                  required>
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete v-model="serviceRequestForm.serviceRequest.productTypeFull"
                                        :items="selectFieldOptions.productTypes"
                                        item-text="displayName"
                                        item-value="productTypeId"
                                        :search-input.sync="typeSearch"
                                        :label="$t('inputLabels.productType')"
                                        :rules="inputRules.generalRules"
                                        :hint="$t('inputMessages.requiredField')"
                                        :no-data-text="$t('noDataText.general')"
                                        persistent-hint
                                        filled
                                        return-object
                                        required>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn1"
                                          :label="serviceRequestForm.serviceRequest.productGroup.nameUniqueIdentifier1  || $t('inputLabels.imei1')"
                                          :rules="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier1 ? inputRules.imeiRule : []"
                                          :messages="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier1 ? $t(`${imeiHint}`) : $t('imeiHintOptional')"
                                          :hint="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier1 ? $t('imeiHint') : $t('imeiHintOptional') "
                                          v-mask="imeiMask"
                                          counter="15"
                                          maxlength="15"
                                          persistent-hint
                                          filled
                                          :disabled="!serviceRequestForm.serviceRequest.productGroup.isUniqueIdentifier1">
                            </v-text-field>
                            <!--<v-slide-y-transition>
                              <v-row class="pa-0 ma-0" v-show="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier1">
                                <v-col class="px-3 py-0 caption">
                                  {{ $t('generateRandomImei') }}
                                </v-col>
                                <v-col cols="auto" class="pa-0">
                                  <v-btn small
                                         color="primary"
                                         @click="randomImei(1)">
                                    {{ $t('button.generate') }}
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-slide-y-transition>-->
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn2"
                                          :label="serviceRequestForm.serviceRequest.productGroup.nameUniqueIdentifier2 || $t('inputLabels.imei2')"
                                          :rules="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier2 ? inputRules.imeiRule : []"
                                          :hint="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier2 ? $t('imeiHint') : $t('inputMessages.optionalField')"
                                          :messages="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier2 ? $t(`${imeiHint}`) : $t('imeiHintOptional')"
                                          v-mask="imeiMask"
                                          counter="15"
                                          maxlength="15"
                                          persistent-hint
                                          filled
                                          :disabled="!serviceRequestForm.serviceRequest.productGroup.isUniqueIdentifier2">
                            </v-text-field>
                            <!--<v-slide-y-transition>
                              <v-row class="pa-0 ma-0" v-show="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier2">
                                <v-col class="px-3 py-0 caption">
                                  {{ $t('generateRandomImei') }}
                                </v-col>
                                <v-col cols="auto" class="pa-0">
                                  <v-btn small
                                         color="primary"
                                         @click="randomImei(2)">
                                    {{ $t('button.generate') }}
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-slide-y-transition>-->
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn3"
                                          :label="serviceRequestForm.serviceRequest.productGroup.nameUniqueIdentifier3  || $t('inputLabels.serialNr')"
                                          :rules="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier3 ? inputRules.snRule : []"
                                          :hint="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier3 ? $t('inputMessages.requiredField') : $t('inputMessages.optionalField')"
                                          counter="22"
                                          maxlength="22"
                                          persistent-hint
                                          filled
                                          :disabled="!serviceRequestForm.serviceRequest.productGroup.isUniqueIdentifier3">
                            </v-text-field>
                            <!--<v-slide-y-transition>
                              <v-row class="pa-0 ma-0" v-show="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier3">
                                <v-col class="px-3 py-0 caption">
                                  {{ $t('generateRandomImei') }}
                                </v-col>
                                <v-col cols="auto" class="pa-0">
                                  <v-btn small
                                         color="primary"
                                         @click="randomImei(3)">
                                    {{ $t('button.generate') }}
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-slide-y-transition>-->
                          </v-col>
                        </v-row>
                      </v-col>
                      <!-- BEÍRÓS DATE PICKER START -->
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            v-model="serviceRequestForm.serviceRequest.dateOfPurchase"
                            :label="$t('inputLabels.dateOfPurchase')"
                            :rules="inputRules.purchaseDateRule"
                            :hint="$t('inputMessages.purchaseDateReq')"
                            persistent-hint
                            v-mask="dateTextMask"
                            filled>
                        </v-text-field>
                      </v-col>
                      <!-- BEÍRÓS DATE PICKER END -->
                      <!--<v-col cols="12" sm="6" md="4">
                        <v-dialog
                            ref="dialog"
                            v-model="datePickerModal"
                            :return-value.sync="serviceRequestForm.serviceRequest.dateOfPurchase"
                            persistent
                            width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="serviceRequestForm.serviceRequest.dateOfPurchase"
                                :label="$t('dateOfPurchase')"
                                :rules="inputRules.generalRules"
                                :hint="$t('inputMessages.requiredField')"
                                persistent-hint
                                filled
                                readonly
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="serviceRequestForm.serviceRequest.dateOfPurchase"
                                         :first-day-of-week="1"
                                         :locale="$i18n.locale"
                                         :max="maxDate"
                                         min="2010-01-01" @input="$refs.dialog.save(serviceRequestForm.serviceRequest.dateOfPurchase)">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="modal = false">{{ $t('button.cancel') }}</v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>-->
                      <!--<v-col cols="12">
                        <v-row>
                          &lt;!&ndash;<v-col cols="12" sm="6">
                            <v-select v-model="serviceRequestForm.serviceRequest.networkLock"
                                      :items="$i18n.locale === 'hu-HU' ? selectFieldOptions.networkLock : $i18n.locale === 'de-DE' ? selectFieldOptions.networkLockDE : selectFieldOptions.networkLockEN"
                                      item-text="name"
                                      item-value="id"
                                      :label="$t('inputLabels.simLock')"
                                      :rules="inputRules.generalRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      persistent-hint
                                      disabled
                                      filled
                                      return-object
                                      required>
                            </v-select>
                          </v-col>&ndash;&gt;
                          <v-col cols="12" sm="6">
                            <v-dialog
                              ref="dialog"
                              v-model="datePickerModal"
                              :return-value.sync="serviceRequestForm.serviceRequest.dateOfPurchase"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="serviceRequestForm.serviceRequest.dateOfPurchase"
                                  :label="$t('dateOfPurchase')"
                                  :hint="$t('inputMessages.optionalField')"
                                  persistent-hint
                                  filled
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="serviceRequestForm.serviceRequest.dateOfPurchase"
                                             :first-day-of-week="1"
                                             :locale="$i18n.locale"
                                             :max="maxDate"
                                             min="2010-01-01" @input="$refs.dialog.save(serviceRequestForm.serviceRequest.dateOfPurchase)">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="modal = false">{{ $t('button.cancel') }}</v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>
                          &lt;!&ndash; <v-col cols="12" sm="6">
                            <v-file-input v-model="attachment.evidence"
                                          :label="$t('evidence')"
                                          :hint="$t('inputMessages.optionalField')"
                                          persistent-hint
                                          accept=".pdf"
                                          counter
                                          show-size
                                          filled
                                          prepend-icon=""
                                          append-icon="mdi-paperclip">
                              <template v-slot:selection="{text}">
                                <v-chip small label color="primary">
                                  {{ text }}
                                </v-chip>
                              </template>
                            </v-file-input>
                          </v-col> &ndash;&gt;
                        </v-row>
                      </v-col>-->
                      <v-col cols="12">
                        <v-textarea v-model="serviceRequestForm.serviceRequest.errorDescription"
                                    :label="$t('troubleDescription')"
                                    :rules="inputRules.lenghtRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    persistent-hint
                                    clearable
                                    :counter='3000'
                                    filled
                                    clear-icon="mdi-close">
                        </v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <v-row justify="end" class="ma-0">
                          <v-btn color="primary"
                                 text
                                 @click="prevStep">
                            {{ $t('button.back') }}
                          </v-btn>
                          <v-btn class="primary"
                                 :disabled="!deviceFormIsValid"
                                 @click="nextStep">
                            {{ $t('button.next') }}
                          </v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-stepper-content>
                <!-- KÉSZÜLÉK ADATOK END -->
                <!-- MEGRENDELŐ ADATOK START -->
                <v-stepper-content step="3">
                  <v-form v-model="customerFormIsValid">
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="serviceRequestForm.customer.lastName"
                                      :label="$t('lastName')"
                                      :rules="inputRules.noSpecialCharAndReq"
                                      :hint="$t('inputMessages.requiredField')"
                                      persistent-hint
                                      filled
                                      :disabled="$store.state.authModule.isAuthenticated">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="serviceRequestForm.customer.firstName"
                                      :label="$t('firstName')"
                                      :rules="inputRules.noSpecialCharAndReq"
                                      :hint="$t('inputMessages.requiredField')"
                                      persistent-hint
                                      filled
                                      :disabled="$store.state.authModule.isAuthenticated">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="serviceRequestForm.customer.email"
                                      :label="$t('email')"
                                      :rules="inputRules.emailRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      persistent-hint
                                      filled
                                      :disabled="$store.state.authModule.isAuthenticated">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="serviceRequestForm.customer.phoneNumber"
                                      :label="$t('phoneNumber')"
                                      :rules="inputRules.phoneRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      persistent-hint
                                      :prefix="getToken() ? '+' : '+36-'"
                                      v-mask=" getToken() ? userPhone : mask"
                                      filled
                                      :disabled="$store.state.authModule.isAuthenticated">
                        </v-text-field>
                      </v-col>
                      <!-- CÍM ADATOK START -->
                      <v-col cols="12">
                        <v-row>
                          <v-row class='pl-6 justify-start'>
                            <v-card-subtitle v-if='deliveryMethod === 2' >
                              <v-row>
                                <span class="subtitle-1">{{$t('parcelLockers') }}: {{this.foxpostAddress}}</span>
                              </v-row>
                              <v-row>
                                <span>{{$t('parcelLockersCode') }}: {{foxpostData.operator_id}}</span>
                              </v-row>
                            </v-card-subtitle>
                          </v-row>
                          <v-col cols="12">
                            <v-text-field v-model="deliveryInAddressString"
                                          :label="!showFoxpost() || !deliveryMethod && !showFoxpost() ? $t('deliveryAndReceiptAddress') : deliveryMethod !== 1 && showFoxpost() ? $t('costumerAddress') : $t('deliveryAndReceiptAddress') "
                                          :rules="inputRules.generalRules"
                                          :hint="$t('inputMessages.requiredField')"
                                          persistent-hint
                                          filled
                                          append-icon="mdi-pencil"
                                          readonly
                                          @click="deliveryInAddressModal = true"
                                          @click:append="deliveryInAddressModal = true">
                            </v-text-field>
                          </v-col>
                          <!--<v-col cols="12" sm="6">
                            <v-text-field v-model="billingAddressString"
                                          :label="$t('invoicingAddress')"
                                          filled
                                          readonly
                                          disabled>
                            </v-text-field>
                          </v-col>-->
                          <!--<v-col cols="12" sm="6">
                            <v-text-field v-model="billingAddressString"
                                          :label="$t('invoicingAddress')"
                                          :rules="inputRules.generalRules"
                                          :hint="$t('inputMessages.requiredField')"
                                          persistent-hint
                                          filled
                                          append-icon="mdi-pencil"
                                          readonly
                                          :disabled="billingAddressIsEqualdeliveryInAddress"
                                          @click="billingAddressModal = true"
                                          @click:append="billingAddressModal = true">
                            </v-text-field>
                            <v-checkbox v-model="billingAddressIsEqualdeliveryInAddress"
                                        class="mt-0" :disabled="!(serviceRequestForm.customer.lastName.length > 1 && deliveryInAddressForm)">
                              <template slot="label">
                            <span class="body-2">
                              {{ $t('invoicingAddressIsEqualShippingAddress') }}
                            </span>
                              </template>
                            </v-checkbox>
                          </v-col>-->
                        </v-row>
                      </v-col>
                      <!-- CÍM ADATOK END -->
                      <!-- REGISZTRÁCIÓ START -->
                      <v-col cols="12" v-if="!$store.state.authModule.isAuthenticated">
                        <v-card class="fill-height">
                          <v-card-title>
                            <v-icon color="primary" class="mr-4">mdi-shield-account</v-icon>
                            <span class="subtitle-1">{{ $t('accountRegistration') }}</span>
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                            <v-row>
                              <v-col cols="12" class="body-1 pb-0">
                                {{ $t('accountRegistrationQuestionOnForm') }}
                              </v-col>
                              <v-col cols="12" class="pt-0">
                                <v-radio-group v-model="serviceRequestForm.accountRegistration"
                                               :rules="inputRules.generalRules"
                                               :hint="$t('inputMessages.requiredField')"
                                               persistent-hint>
                                  <v-radio value="yes" :label="$t('inputLabels.accountRegistrationTrue')"></v-radio>
                                  <v-radio value="no" :label="$t('inputLabels.accountRegistrationFalse')"></v-radio>
                                </v-radio-group>
                              </v-col>
                            </v-row>
                            <v-expand-transition>
                              <v-row v-show="serviceRequestForm.accountRegistration === 'yes'">
                                <v-col cols="12">
                                  <p class="body-1">
                                    Kérjük a lenti mezőben adj meg egy jelszót. Ezzel tudsz majd bejelentkezni a felhasználói fiókodba. A bejelentkezési e-mail cím az Ügyfél adatoknál megadott (<b>{{ serviceRequestForm.customer.email }}</b>) e-mail címed lesz.
                                  </p>
                                  <p>
                                    A jelszónak kötelezően tartalmaznia kell minimum 1db kis-, 1 db nagybetűt és minimum 1 db számot. A jelszó hossza minimum 8 karakter kell hogy legyen!
                                  </p>
                                </v-col>
                                <v-col cols="12" sm="6" offset-sm="3">
                                  <v-text-field v-model="serviceRequestForm.customer.password"
                                                :label="$t('inputLabels.password')"
                                                :rules="serviceRequestForm.accountRegistration === 'yes' ? inputRules.min8 : []"
                                                :hint="serviceRequestForm.accountRegistration === 'yes' ? $t('inputMessages.requiredField') : ''"
                                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                :type="show1 ? 'text' : 'password'"
                                                persistent-hint
                                                counter="30"
                                                filled
                                                required
                                                @click:append="show1 = !show1">
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-expand-transition>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <!-- REGISZTRÁCIÓ END -->
                      <v-col cols="12">
                        <v-row justify="end" class="ma-0">
                          <v-btn color="primary"
                                 text
                                 @click="prevStep">
                            {{ $t('button.back') }}
                          </v-btn>
                          <v-btn class="primary"
                                 :disabled="!customerFormIsValid"
                                 @click="nextStep">
                            {{ $t('button.next') }}
                          </v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-stepper-content>
                <!-- MEGRENDELŐ ADATOK END -->
                <!-- VÉGLEGESÍTÉS START -->
                <v-stepper-content step="4">
                  <!-- KÉSZÜLÉK ADATOK ELLENŐRZÉS START -->
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="serviceRequestForm.serviceRequest.insuranceNumber"
                                    :label="sourceProp === 'mostmobil' ? $t('requestIdOrWorksheetNumber') : $t('damageNumber')"
                                    disabled
                                    class="toUp"
                                    filled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-file-input v-model="attachment.invoice"
                                    :label="$t('inputLabels.purchaseInvoice')"
                                    disabled
                                    persistent-hint
                                    accept=".pdf"
                                    counter
                                    show-size
                                    filled
                                    prepend-icon=""
                                    append-icon="mdi-paperclip">
                        <template v-slot:selection="{text}">
                          <v-chip small label color="grey lighten-1">
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-file-input v-model="attachment.warrantyCard"
                                    :label="$t('inputLabels.purchaseWarrantyTicket')"
                                    disabled
                                    persistent-hint
                                    accept=".pdf"
                                    counter
                                    show-size
                                    filled
                                    prepend-icon=""
                                    append-icon="mdi-paperclip">
                        <template v-slot:selection="{text}">
                          <v-chip small label color="grey lighten-1">
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="serviceRequestForm.serviceRequest.brand.name"
                                    :label="$t('brand')"
                                    disabled
                                    filled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="serviceRequestForm.serviceRequest.productGroup.name"
                                    :label="$t('inputLabels.productGroup')"
                                    disabled
                                    filled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="serviceRequestForm.serviceRequest.productType.name"
                                    :label="$t('productType')"
                                    disabled
                                    persistent-hint
                                    filled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" sm="6" md="4" v-if="serviceRequestForm.serviceRequest.productGroup.isUniqueIdentifier1">
                          <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn1"
                                        :label="serviceRequestForm.serviceRequest.productGroup.nameUniqueIdentifier1"
                                        v-mask="imeiMask"
                                        counter="15"
                                        maxlength="15"
                                        disabled
                                        filled>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" v-if="serviceRequestForm.serviceRequest.productGroup.isUniqueIdentifier2">
                          <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn2"
                                        :label="serviceRequestForm.serviceRequest.productGroup.nameUniqueIdentifier2"
                                        :hint="$t('inputMessages.optionalField')"
                                        v-mask="imeiMask"
                                        counter="15"
                                        maxlength="15"
                                        disabled
                                        persistent-hint
                                        filled>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" v-if="serviceRequestForm.serviceRequest.productGroup.isUniqueIdentifier3">
                          <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn3"
                                        :label="serviceRequestForm.serviceRequest.productGroup.nameUniqueIdentifier3"
                                        :hint="$t('inputMessages.optionalField')"
                                        disabled
                                        persistent-hint
                                        filled>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!--<v-col cols="12" sm="6" md="4">
                      <v-select v-model="serviceRequestForm.serviceRequest.networkLock"
                                :items="$i18n.locale === 'hu-HU' ? selectFieldOptions.networkLock : $i18n.locale === 'de-DE' ? selectFieldOptions.networkLockDE : selectFieldOptions.networkLockEN"
                                item-text="name"
                                item-value="id"
                                :label="$t('inputLabels.simLock')"
                                disabled
                                persistent-hint
                                filled
                                return-object
                                required>
                      </v-select>
                    </v-col>-->
                    <v-col cols="12">
                      <v-row>
                        <!-- BEÍRÓS DATE PICKER START -->
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                              v-model="serviceRequestForm.serviceRequest.dateOfPurchase"
                              :label="$t('inputLabels.dateOfPurchase')"
                              :hint="$t('inputMessages.requiredField')"
                              persistent-hint
                              disabled
                              v-mask="dateTextMask"
                              filled>
                          </v-text-field>
                        </v-col>
                        <!-- BEÍRÓS DATE PICKER END -->
                        <!--<v-col cols="12" sm="6" md="4">
                          <v-dialog
                              ref="dialog"
                              v-model="datePickerModal"
                              :return-value.sync="serviceRequestForm.serviceRequest.dateOfPurchase"
                              persistent
                              width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                  v-model="serviceRequestForm.serviceRequest.dateOfPurchase"
                                  :label="$t('dateOfPurchase')"
                                  disabled
                                  persistent-hint
                                  filled
                                  readonly
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="serviceRequestForm.serviceRequest.dateOfPurchase"
                                           :first-day-of-week="1"
                                           :locale="$i18n.locale"
                                           :max="maxDate"
                                           min="2010-01-01" @input="$refs.dialog.save(serviceRequestForm.serviceRequest.dateOfPurchase)">
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="modal = false">{{ $t('button.cancel') }}</v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-col>-->
                        <!-- <v-col cols="12" sm="6" md="4">
                          <v-file-input v-model="attachment.evidence"
                                        :label="$t('evidence')"
                                        disabled
                                        persistent-hint
                                        accept=".pdf"
                                        counter
                                        show-size
                                        filled
                                        prepend-icon=""
                                        append-icon="mdi-paperclip">
                            <template v-slot:selection="{text}">
                              <v-chip small label color="primary">
                                {{ text }}
                              </v-chip>
                            </template>
                          </v-file-input>
                        </v-col> -->
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea v-model="serviceRequestForm.serviceRequest.errorDescription"
                                  :label="$t('troubleDescription')"
                                  disabled
                                  persistent-hint
                                  clearable
                                  filled
                                  clear-icon="mdi-close">
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <!-- KÉSZÜLÉK ADATOK ELLENŐRZÉS END -->
                  <v-row>
                    <v-col cols="12">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <!-- MEGRENDELŐ ADATOK ELLENŐRZÉS START -->
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="serviceRequestForm.customer.lastName"
                                    :label="$t('lastName')"
                                    disabled
                                    persistent-hint
                                    filled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="serviceRequestForm.customer.firstName"
                                    :label="$t('firstName')"
                                    disabled
                                    persistent-hint
                                    filled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="serviceRequestForm.customer.email"
                                    :label="$t('email')"
                                    disabled
                                    persistent-hint
                                    filled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="serviceRequestForm.customer.phoneNumber"
                                    :label="$t('phoneNumber')"
                                    disabled
                                    persistent-hint
                                    :prefix="getToken() ? '+' : '+36'"
                                    v-mask="getToken() ? userPhone : mask"
                                    filled>
                      </v-text-field>
                    </v-col>
                    <!-- CÍM ADATOK START -->
                    <v-col cols="12">
                      <v-row>
                        <v-row class='pl-6 justify-start'>
                          <v-card-subtitle v-if='deliveryMethod === 2' >
                            <v-row>
                              <span class="subtitle-1">{{$t('parcelLockers') }}: {{this.foxpostAddress}}</span>
                            </v-row>
                            <v-row>
                              <span>{{$t('parcelLockersCode') }}: {{foxpostData.operator_id}}</span>
                            </v-row>
                          </v-card-subtitle>
                        </v-row>
                        <v-col cols="12">
                          <v-text-field v-model="deliveryInAddressString"
                                        :label="$t('deliveryAndReceiptAddress')"
                                        disabled
                                        persistent-hint
                                        filled
                                        readonly>
                          </v-text-field>
                        </v-col>
                        <!--<v-col cols="12" sm="6">
                          <v-text-field v-model="billingAddressString"
                                        :label="$t('invoicingAddress')"
                                        disabled
                                        persistent-hint
                                        filled
                                        readonly>
                          </v-text-field>
                        </v-col>-->
                      </v-row>
                    </v-col>
                    <!-- CÍM ADATOK END -->
                  </v-row>
                  <!-- MEGRENDELŐ ADATOK ELLENŐRZÉS END -->
                  <!-- ACTION BUTTONS START -->
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-checkbox
                          v-model="serviceRequestForm.gdpr"
                          :rules="inputRules.gdprRules"
                          required
                          class="ma-0"
                      >
                        <template slot="label">
                          <span v-if="$i18n.locale === 'hu-HU' || $i18n.locale === 'hu'" class="body-2">Az <a :href="`${publicPath}doc/Adatvedelmi_Tajekoztato_Honlap_Latogatoknak.pdf`" target="_blank" @click.stop>Adatvédelmi tájékoztatót</a>&nbsp;elolvastam és elfogadom.</span>
                          <span v-if="$i18n.locale === 'en-EN' || $i18n.locale === 'en'" class="body-2">I have read and accepted the&nbsp;<a :href="`${publicPath}doc/Adatvedelmi_Tajekoztato_Honlap_Latogatoknak.pdf`" target="_blank" @click.stop>Privacy Policy</a>.</span>
                          <span v-if="$i18n.locale === 'de-DE' || $i18n.locale === 'de'" class="body-2">Ich habe die&nbsp;<a :href="`${publicPath}doc/Adatvedelmi_Tajekoztato_Honlap_Latogatoknak.pdf`" target="_blank" @click.stop>Datenschutzbestimmungen</a> gelesen und akzeptiere sie.</span>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-checkbox
                          v-model="serviceRequestForm.termsAndCond"
                          :rules="inputRules.gdprRules"
                          required
                          class="ma-0"
                      >
                        <template slot="label">
                            <span v-if="$i18n.locale === 'hu-HU' || $i18n.locale === 'hu'" class="body-2">
                              Az&nbsp;<a :href="`${publicPath}doc/TMX_Terms_and_Conditions_hu.pdf`" target="_blank" @click.stop>Általános Szerződési Feltételeket</a>&nbsp;elolvastam és elfogadom.
                            </span>
                          <span v-if="$i18n.locale === 'en-EN' || $i18n.locale === 'en'" class="body-2">
                              I have read and accepted the&nbsp;<a :href="`${publicPath}doc/TMX_Terms_and_Conditions_en.pdf`" target="_blank" @click.stop>Terms and Conditions</a>.
                            </span>
                          <span v-if="$i18n.locale === 'de-DE' || $i18n.locale === 'de'" class="body-2">
                              Ich habe die&nbsp;<a :href="`${publicPath}doc/TMX_Terms_and_Conditions_de.pdf`" target="_blank" @click.stop>AGB</a> gelesen und akzeptiere sie.
                            </span>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-row justify="end" class="ma-0">
                        <v-btn color="primary"
                               text
                               @click="prevStep">
                          {{ $t('button.back') }}
                        </v-btn>
                        <v-btn class="primary" :disabled="!((serviceRequestForm.termsAndCond && serviceRequestForm.gdpr) && (deviceFormIsValid && customerFormIsValid))" @click="useNoRegPopupBeforeSend">
                          {{ $t('button.send') }}
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                  <!-- ACTION BUTTONS END -->
                </v-stepper-content>
                <!-- VÉGLEGESÍTÉS END -->
                <!-- KÉSZ START -->
                <v-stepper-content step="5">
                  <v-row>
                    <v-col cols="12" class="pt-12">
                      <div v-html="$t(`${responseDialog.response.message}`)"></div>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <!-- KÉSZ END -->
              </v-stepper-items>
            </v-stepper>
          </v-card>
        </v-container>
      </div>
    </div>
    <!-- SZÁLLÍTÁSI CÍM MODAL -->
    <v-dialog v-model="deliveryInAddressModal" max-width="1200">
      <v-card tile>
        <v-card-title class="third">
          <v-icon color="white" class="mr-4">mdi-truck-check-outline</v-icon>
          <span class="white--text" v-if="!showFoxpost()">{{ $t('deliveryAndReceiptAddress') }}</span>
          <span class="white--text" v-if="showFoxpost()">{{ $t('costumerAddress') }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="[deliveryInAddressModal = false , shippingAddressStepper = 1 ,deliveryMethod = 1]" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-stepper v-model="shippingAddressStepper">
          <v-stepper-header  v-if='showFoxpost()'>
            <v-stepper-step
              :complete="shippingAddressStepper > 1"
              step="1"
            >
              {{ $t('requireAddress') }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :complete="shippingAddressStepper > 2"
              step="2"
            >
              {{ $t('deliveryMethod') }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :complete="shippingAddressStepper > 3"
              step="3">
              {{ $t('parcelLockersChose') }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :complete="shippingAddressStepper > 4"
              step="4">
              {{ $t('parcelLockersSubmit') }}
            </v-stepper-step>
          </v-stepper-header>
          <v-divider v-if='deliveryMethod === 2'></v-divider>
          <v-stepper-items>
            <v-stepper-content step="0">
              <v-select v-model="customerShippingAddress"
                        :items="selectFieldOptions.customerShippingAddresses"
                        item-text="label"
                        item-value="id"
                        :label="$t('inputLabels.selectShippingAddress')"
                        :rules="inputRules.generalRules"
                        :hint="$t('inputMessages.requiredField')"
                        persistent-hint
                        filled
                        return-object
                        required>
              </v-select>
            </v-stepper-content>
            <v-stepper-content step="1" class="pa-0">
              <v-card-text class="pa-6">
                <v-form v-model="deliveryInAddressForm">
                  <v-row>
                    <v-col cols="12" v-if="getToken()">
                      <v-select v-model="customerShippingAddress"
                                :items="selectFieldOptions.customerShippingAddresses"
                                item-text="label"
                                item-value="id"
                                :label="$t('inputLabels.selectShippingAddress')"
                                :rules="inputRules.generalRules"
                                :hint="$t('inputMessages.requiredField')"
                                persistent-hint
                                filled
                                return-object
                                required>
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="customerShippingAddress.id === '5dC]Y??9f@TmbJFv' || !getToken()">
                      <v-autocomplete v-model="serviceRequestForm.deliveryInAddress.postalCode"
                                      :items="selectFieldOptions.postalCodes"
                                      item-text="address.postalCode"
                                      item-value="address.postalCode"
                                      :search-input.sync="postalCodeSearch"
                                      :label="$t('inputLabels.postalCode')"
                                      :rules="inputRules.phoneRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      :no-data-text="$t('inputMessages.noDataText')"
                                      persistent-hint
                                      filled
                                      maxlength="4"
                                      required
                                      @change="onPostalCodeSelect"
                                      @click:clear="clearAllAddressInputs"
                                      clearable>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="customerShippingAddress.id !== '5dC]Y??9f@TmbJFv' && getToken() ">
                      <v-text-field v-model="serviceRequestForm.deliveryInAddress.postalCode"
                                    :items="selectFieldOptions.postalCodes"
                                    item-text="address.postalCode"
                                    item-value="address.postalCode"
                                    :search-input.sync="postalCodeSearch"
                                    :label="$t('inputLabels.postalCode')"
                                    :rules="inputRules.phoneRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    :no-data-text="$t('inputMessages.noDataText')"
                                    persistent-hint
                                    filled
                                    required
                                    disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="customerShippingAddress.id === '5dC]Y??9f@TmbJFv' || !getToken()">
                      <v-autocomplete v-model="serviceRequestForm.deliveryInAddress.city"
                                      :items="selectFieldOptions.cities"
                                      item-value="address.city"
                                      item-text="address.city"
                                      :search-input.sync="citySearch"
                                      :label="$t('inputLabels.city')"
                                      :rules="inputRules.generalRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      :no-data-text="$t('inputMessages.noDataText')"
                                      persistent-hint
                                      @change='getPostalCodeByCity'
                                      filled
                                      required
                                      clearable
                                      @click:clear="clearAllAddressInputs">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="customerShippingAddress.id !== '5dC]Y??9f@TmbJFv' && getToken()">
                      <v-text-field v-model="serviceRequestForm.deliveryInAddress.city"
                                    :items="selectFieldOptions.cities"
                                    item-value="address.city"
                                    item-text="address.city"
                                    :search-input.sync="citySearch"
                                    :label="$t('inputLabels.city')"
                                    :rules="inputRules.generalRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    :no-data-text="$t('inputMessages.noDataText')"
                                    persistent-hint
                                    filled
                                    disabled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="8" v-if="customerShippingAddress.id === '5dC]Y??9f@TmbJFv' || !getToken()">
                      <v-combobox v-model="streetIdeiglenes"
                                  :items="selectFieldOptions.streets"
                                  item-value="address.street"
                                  item-text="address.street"
                                  :search-input.sync="streetSearch"
                                  :label="$t('inputLabels.publicPlaceName')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  :no-data-text="$t('inputMessages.noDataText')"
                                  persistent-hint
                                  filled
                                  required
                                  @change="onPublicPlaceSelect"
                                  clearable>
                      </v-combobox>
                    </v-col>
                    <v-col cols="12" sm="8" v-if="customerShippingAddress.id !== '5dC]Y??9f@TmbJFv' && getToken()">
                      <v-text-field v-model="serviceRequestForm.deliveryInAddress.publicPlaceName + ' ' + serviceRequestForm.deliveryInAddress.publicPlaceType"
                                    :items="selectFieldOptions.streets"
                                    item-value="address.street"
                                    item-text="address.street"
                                    :search-input.sync="streetSearch"
                                    :label="$t('inputLabels.publicPlaceName')"
                                    :rules="inputRules.generalRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    :no-data-text="$t('inputMessages.noDataText')"
                                    persistent-hint
                                    filled
                                    required
                                    disabled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" v-if="customerShippingAddress.id === '5dC]Y??9f@TmbJFv' || !getToken()">
                      <v-text-field v-model="serviceRequestForm.deliveryInAddress.streetNumber"
                                    :label="$t('inputLabels.streetNumber')"
                                    :rules="inputRules.generalRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    :no-data-text="$t('inputMessages.noDataText')"
                                    counter="30"
                                    max-length="30"
                                    persistent-hint
                                    filled
                                    required
                                    clearable>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" v-if="customerShippingAddress.id !== '5dC]Y??9f@TmbJFv' && getToken()">
                      <v-text-field v-model="serviceRequestForm.deliveryInAddress.streetNumber"
                                    :label="$t('inputLabels.streetNumber')"
                                    :rules="inputRules.generalRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    :no-data-text="$t('inputMessages.noDataText')"
                                    counter="30"
                                    max-length="30"
                                    persistent-hint
                                    filled
                                    required
                                    disabled
                                    clearable>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea v-model="serviceRequestForm.deliveryInAddress.other"
                                  :label="$t('infoToDeliver')"
                                  :rules="inputRules.noSpecialChar"
                                  :hint="$t('inputMessages.optionalField')"
                                  persistent-hint
                                  clearable
                                  filled
                                  clear-icon="mdi-close"
                                  @keydown.prevent.enter="preventEnter"
                                  maxlength="50"
                                  counter="50">
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="px-6 pb-6">
                <v-spacer></v-spacer>
                <v-btn v-if='showFoxpost()'
                       color="primary"
                       :disabled="checkAddress()"
                       @click="shippingAddressStepper = 2"
                >
                  {{ $t('button.next') }}
                </v-btn>
                <v-btn text @click="deliveryInAddressModal = false">
                  {{ $t('button.cancel') }}
                </v-btn>
                <!--<v-btn text @click="stepBackDeliveryInAddressModal" color="primary" v-if="$store.state.authModule.isAuthenticated">
                  {{ $t('button.backToMyAddresses') }}
                </v-btn>-->
                <v-btn v-if='!showFoxpost()' class="primary" @click="saveDeliveryInAddressData"  :disabled="!(deliveryInAddressForm && serviceRequestForm.deliveryInAddress.publicPlaceType.length > 0)">
                  {{ $t('button.save') }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-row>
                <v-col cols='12' sm='6'>
                  <v-card class='ma-6 pa-5' height="200px">
                    <v-row justify="center">
                      <v-btn color='primary' class='pa-2 mt-2 mb-3' @click='[deliveryMethod = 1, saveDeliveryInAddressData()] '>{{$t('deliveryService')}}</v-btn>
                      <span class="ma-3">{{$t('deliveryServiceText')}}</span>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols='12' sm='6'>
                  <v-card class='ma-6 pa-5' height="200px">
                    <v-row justify="center">
                      <v-btn color='primary' class='pa-2 mt-2 mb-3' @click='[deliveryMethod = 2 , shippingAddressStepper = 3 ]'>{{$t('foxpost')}}</v-btn>
                      <span class="ma-3">{{$t('foxpostText')}}</span>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <v-row justify='end'  class='mr-1 mb-1 mt-1 pa-2'>
                <v-btn color='primary' @click="shippingAddressStepper = 1">
                  {{ $t('button.back') }}
                </v-btn>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="3">
              <iframe  loading="lazy" src="https://cdn.foxpost.hu/apt-finder/v1/app/" :height='screenCheck()' width='100%'></iframe>
              <v-row class='justify-end mb-1 mt-2 mr-1' >
                <v-btn color='primary' @click="[shippingAddressStepper = 2, deliveryMethod = null]">
                  {{ $t('button.back') }}
                </v-btn>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-row class='pa-2 justify-center'>
                <v-col cols="12" sm="6">
                  <v-card class='justify-center'>
                    <v-card-title class='justify-center primary white--text'>{{$t('parcelLockersSubmit') }}</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text align='center'> {{$t('parcelLockersChosen') }}: {{foxpostAddress}}</v-card-text>
                    <v-row class='justify-center'>
                      <span>{{$t('parcelLockersCode') }}: {{ foxpostData.operator_id }}</span>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class='pa-2 justify-center' v-if="foxpostPhone === 1">
                <v-col cols="12" sm="6">
                  <v-card class='justify-center'>
                    <v-card-title class='justify-center primary white--text'>Mobil telefonszám megadása</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text align='center'>{{$t('foxpostPhoneNumberText') }} <br> {{$t('phoneServices') }}</v-card-text>
                    <v-row class='justify-center'>
                      <v-col cols='12' sm='6'>
                        <v-text-field v-model="foxpostPhoneNumber"
                                      :label="$t('inputLabels.phone')"
                                      :rules="inputRules.phoneRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      persistent-hint
                                      :prefix="getToken() ? '+' : '+36'"
                                      v-mask="getToken() ? userPhone : mask"
                                      filled
                                      required
                                      :disabled="foxpostPhone !== 1">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class='pa-2 mb-1 justify-end'>
                <v-btn class="primary" @click="saveDeliveryInAddressData" :disabled=" getToken() ? !foxpostPhoneNumberCheck() : foxpostPhoneNumberCheck()">
                  {{ $t('button.save') }}
                </v-btn>
                <v-btn color='primary'  text @click="shippingAddressStepper = 3">
                  {{ $t('button.back') }}
                </v-btn>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="deliveryInAddressModal" max-width="1200px" persistent>
       <v-card tile>
         <v-card-title class="third">
           <v-icon color="white" class="mr-4">mdi-truck-check-outline</v-icon>
           <span class="white--text">{{ $t('deliveryAndReceiptAddress') }}</span>
           <v-spacer></v-spacer>
           <v-icon @click="deliveryInAddressModal = false" color="white">
             mdi-close
           </v-icon>
         </v-card-title>
         <v-stepper v-model="shippingAddressStepper">
           <v-stepper-items>
             <v-stepper-content step="1">
               <v-select v-model="customerShippingAddress"
                         :items="selectFieldOptions.customerShippingAddresses"
                         item-text="label"
                         item-value="id"
                         :label="$t('inputLabels.selectShippingAddress')"
                         :rules="inputRules.generalRules"
                         :hint="$t('inputMessages.requiredField')"
                         persistent-hint
                         filled
                         return-object
                         required>
               </v-select>
             </v-stepper-content>
             <v-stepper-content step="2" class="pa-0">
               <v-card-text class="pa-6">
                 <v-form v-model="deliveryInAddressForm">
                   <v-row>
                     <v-col cols="12" sm="6">
                       <v-autocomplete v-model="serviceRequestForm.deliveryInAddress.postalCode"
                                       :items="selectFieldOptions.postalCodes"
                                       item-text="address.postalCode"
                                       item-value="address.postalCode"
                                       :search-input.sync="postalCodeSearch"
                                       :label="$t('inputLabels.postalCode')"
                                       :rules="inputRules.phoneRules"
                                       :hint="$t('inputMessages.requiredField')"
                                       :no-data-text="$t('inputMessages.noDataText')"
                                       persistent-hint
                                       filled
                                       maxlength="4"
                                       required
                                       @change="onPostalCodeSelect"
                                       @click:clear="clearAllAddressInputs"
                                       clearable>
                       </v-autocomplete>
                     </v-col>
                     <v-col cols="12" sm="6">
                       <v-autocomplete v-model="serviceRequestForm.deliveryInAddress.city"
                                       :items="selectFieldOptions.cities"
                                       item-value="address.city"
                                       item-text="address.city"
                                       :search-input.sync="citySearch"
                                       :label="$t('inputLabels.city')"
                                       :rules="inputRules.generalRules"
                                       :hint="$t('inputMessages.requiredField')"
                                       :no-data-text="$t('inputMessages.noDataText')"
                                       persistent-hint
                                       filled
                                       @change='getPostalCodeByCity'
                                       required
                                       clearable
                                       @click:clear="clearAllAddressInputs">
                       </v-autocomplete>
                     </v-col>
                     <v-col cols="12" sm="8">
                       <v-combobox v-model="streetIdeiglenes"
                                   :items="selectFieldOptions.streets"
                                   item-value="address.street"
                                   item-text="address.street"
                                   :search-input.sync="streetSearch"
                                   :label="$t('inputLabels.publicPlaceName')"
                                   :rules="inputRules.generalRules"
                                   :hint="$t('inputMessages.requiredField')"
                                   :no-data-text="$t('inputMessages.noDataText')"
                                   persistent-hint
                                   filled
                                   required
                                   @change="onPublicPlaceSelect"
                                   clearable>
                       </v-combobox>
                     </v-col>
                     <v-col cols="12" sm="4">
                       <v-text-field v-model="serviceRequestForm.deliveryInAddress.streetNumber"
                                     :label="$t('inputLabels.streetNumber')"
                                     :rules="inputRules.generalRules"
                                     :hint="$t('inputMessages.requiredField')"
                                     :no-data-text="$t('inputMessages.noDataText')"
                                     counter="30"
                                     max-length="30"
                                     persistent-hint
                                     filled
                                     required
                                     clearable>
                       </v-text-field>
                     </v-col>
                     <v-col cols="12">
                       <v-textarea v-model="serviceRequestForm.deliveryInAddress.other"
                                   :label="$t('infoToDeliver')"
                                   :rules="inputRules.noSpecialChar"
                                   :hint="$t('inputMessages.optionalField')"
                                   persistent-hint
                                   clearable
                                   filled
                                   clear-icon="mdi-close"
                                   @keydown.prevent.enter="preventEnter"
                                   maxlength="50"
                                   counter="50">
                       </v-textarea>
                     </v-col>
                   </v-row>
                 </v-form>
               </v-card-text>
               <v-card-actions class="px-6 pb-6">
                 <v-spacer></v-spacer>
                 <v-btn text @click="cancelDeliveryInAddressModal">
                   {{ $t('button.cancel') }}
                 </v-btn>
                 <v-btn text @click="stepBackDeliveryInAddressModal" color="primary" v-if="$store.state.authModule.isAuthenticated">
                   {{ $t('button.backToMyAddresses') }}
                 </v-btn>
                 <v-btn class="primary" @click="saveDeliveryInAddressData" :disabled="!deliveryInAddressForm">
                   {{ $t('button.save') }}
                 </v-btn>
               </v-card-actions>
             </v-stepper-content>
           </v-stepper-items>
         </v-stepper>
       </v-card>
     </v-dialog> -->
    <!-- SZÁMLÁZÁSI CÍM MODAL -->
    <v-dialog v-model="billingAddressModal" max-width="800" persistent>
      <v-card tile>
        <v-card-title class="third">
          <v-icon color="white" class="mr-4">mdi-file-account-outline</v-icon>
          <span class="white--text">{{ $t('invoicingAddress') }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="billingAddressModal = false" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-stepper v-model="billingAddressStepper">
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-select v-model="customerBillingAddress"
                        :items="selectFieldOptions.customerBillingAddresses"
                        item-text="label"
                        item-value="id"
                        :label="$t('inputLabels.selectBillingAddress')"
                        :rules="inputRules.generalRules"
                        :hint="$t('inputMessages.requiredField')"
                        persistent-hint
                        filled
                        return-object
                        required>
              </v-select>
            </v-stepper-content>
            <v-stepper-content step="2" class="px-0">
              <v-card-text class="pa-6">
                <v-form v-model="billingAddressForm">
                  <v-row>
                    <v-col cols="12" class="pt-0">
                      <v-radio-group v-model="serviceRequestForm.billingAddress.isCompany"
                                     row mandatory class="mt-0" hide-details>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <div :class="serviceRequestForm.billingAddress.isCompany === 0 ? 'bra' : 'bri'"
                                 class="pa-3">
                              <v-radio :value="0">
                                <template slot="label">
                                  <span class="text-uppercase br-label">{{ $t('inputLabels.privatePerson') }}</span>
                                </template>
                              </v-radio>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <div :class="serviceRequestForm.billingAddress.isCompany === 1 ? 'bra' : 'bri'"
                                 class="pa-3">
                              <v-radio :value="1">
                                <template slot="label">
                                  <span class="text-uppercase br-label">{{ $t('inputLabels.company') }}</span>
                                </template>
                              </v-radio>
                            </div>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="serviceRequestForm.billingAddress.billingName"
                                    :label="$t('invoicingName')"
                                    :rules="inputRules.generalRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    persistent-hint
                                    filled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="serviceRequestForm.billingAddress.taxNumber"
                                    :label="$t('vatNumber')"
                                    :rules="serviceRequestForm.billingAddress.isCompany === 1 ? inputRules.vatRules : []"
                                    :hint="serviceRequestForm.billingAddress.isCompany === 1 ? $t('inputMessages.requiredField') : $t('inputMessages.optionalField')"
                                    v-mask="taxNumberMaskHU"
                                    persistent-hint
                                    filled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete v-model="serviceRequestForm.billingAddress.postalCode"
                                      :items="selectFieldOptions.postalCodesInvoice"
                                      item-text="address.postalCode"
                                      item-value="address.postalCode"
                                      :search-input.sync="postalCodeSearchInvoice"
                                      :label="$t('inputLabels.postalCode')"
                                      :rules="inputRules.phoneRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      :no-data-text="$t('inputMessages.noDataText')"
                                      persistent-hint
                                      filled
                                      maxlength="4"
                                      required
                                      @change="onPostalCodeSelectInvoice"
                                      @click:clear="clearAllAddressInputsInvoice"
                                      clearable>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete v-model="serviceRequestForm.billingAddress.city"
                                      :items="selectFieldOptions.citiesInvoice"
                                      item-value="address.city"
                                      item-text="address.city"
                                      :search-input.sync="citySearchInvoice"
                                      :label="$t('inputLabels.city')"
                                      :rules="inputRules.generalRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      :no-data-text="$t('inputMessages.noDataText')"
                                      persistent-hint
                                      filled
                                      required
                                      clearable
                                      @change='getPostalCodeByCityInvoice'
                                      @click:clear="clearAllAddressInputsInvoice">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-combobox v-model="streetIdeiglenesInvoice"
                                  :items="selectFieldOptions.streetsInvoice"
                                  item-value="address.street"
                                  item-text="address.street"
                                  :search-input.sync="streetSearchInvoice"
                                  :label="$t('inputLabels.publicPlaceName')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  :no-data-text="$t('inputMessages.noDataText')"
                                  persistent-hint
                                  filled
                                  required
                                  @change="onPublicPlaceSelectInvoice"
                                  clearable>
                      </v-combobox>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field v-model="serviceRequestForm.billingAddress.streetNumber"
                                    :label="$t('inputLabels.streetNumber')"
                                    :rules="inputRules.generalRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    :no-data-text="$t('inputMessages.noDataText')"
                                    counter="30"
                                    max-length="30"
                                    persistent-hint
                                    filled
                                    required
                                    clearable>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea v-model="serviceRequestForm.billingAddress.other"
                                  :label="$t('infoToDeliver')"
                                  :rules="inputRules.noSpecialChar"
                                  :hint="$t('inputMessages.optionalField')"
                                  persistent-hint
                                  clearable
                                  filled
                                  clear-icon="mdi-close"
                                  maxlength="50"
                                  counter="50">
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="px-6 pb-6">
                <v-spacer></v-spacer>
                <v-btn text @click="billingAddressModal = false">
                  {{ $t('button.cancel') }}
                </v-btn>
                <v-btn class="primary" @click="saveBillingAddressData" :disabled="!billingAddressForm">
                  {{ $t('button.save') }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <!-- NO REG POPUP START -->
    <v-dialog v-model="noRegPopUp" persistent max-width="440">
      <v-card>
        <v-card-title class="warning py-12 justify-center">
          <v-icon color="white" size="62" class="my-5 pulse">
            mdi-bell-ring
          </v-icon>
        </v-card-title>
        <v-card-text class="pa-5">
          <div v-html="$t('regIsBetterText')"></div>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-row class="mx-0">
            <v-col cols="12" class="pa-0 mb-4">
              <v-btn class="primary" block @click="closeNoRegPopup">
                {{ $t('button.continueWithReg') }}
              </v-btn>
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-btn text color="primary" block @click="sendContactForm">
                {{ $t('button.continueWithoutReg') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- NO REG POPUP END -->
    <!-- RESPONSE DIALOGS START -->
    <v-dialog v-model="loadingDialog.visible" width="90%" max-width="340" :persistent="true">
      <loading-dialog v-bind:loading-message="loadingDialog.message" />
    </v-dialog>
    <v-dialog v-model="responseDialog.visible" width="90%" max-width="340" :persistent="true">
      <response-dialog v-bind:response-object="responseDialog.response"
                       v-on:close-dialog="closeResponseDialog"/>
    </v-dialog>
    <v-dialog v-model="noFileDialog.visible" width="90%" max-width="340" :persistent="true">
      <response-dialog v-bind:response-object="noFileDialog.response"
                       v-on:close-dialog="closeNoFileDialog"/>
    </v-dialog>
    <!-- RESPONSE DIALOGS END -->
    <!-- NOT VALID PDF DIALOG START -->
    <v-dialog v-model="attachmentChecker.visible" width="90%" max-width="340" :persistent="true">
      <response-dialog v-bind:response-object="attachmentChecker.response"
                       v-on:close-dialog="closeNotValidPdfDialog"/>
    </v-dialog>
    <!-- NOT VALID PDF DIALOG END -->
  </div>
</template>

<script>
// import { LUHN_CHECK } from "../../store/actions.type";
import dayjs from "dayjs";
import LoadingDialog from "../dialogs/LoadingDialog";
import ResponseDialog from "../dialogs/ResponseDialog";
import {
  HEREGETPOSTALCODESBYCOUNTRY,
  HEREGETCITYBYPOSTALCODE,
  HEREGETSTREETBYPOSTALCODE,
  HEREGETPOSTALCODESBYCITY,
} from 'src/utils/hereAPI';
import { getToken, getUid } from 'src/utils/jwtHelper';
import { TC_REGEXP, TNC_REGEXP, AC_REGEXP, MC_REGEXP, H_REGEXP, START_2DIG, O2_INSURANCE_REGEXP } from 'src/utils/systemParameters';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import UserCanSeeTheContent from 'src/components/shared/UserCanSeeTheContent';

export default {
  name: 'BiztositasiSzervizgarForm',
  components: {
    LoadingDialog, ResponseDialog, LoadingComponent, UserCanSeeTheContent,
  },
  props: {
    sourceProp: {
      type: String,
      default: 'general',
    }
  },
  async beforeMount() {
    await this.callShowForm();
    this.isUserCanSeeTheForm = await this.CheckIsUserCanSeeTheForm();
    this.isFormLoading = false;
    await this.callShowPaymentMethodOfCustomerType();
    this.setDefaultCountry();
    this.setMaxAvailableDateOfPurchase();
    if (await getToken()) {
      if (await getUid()) {
        await this.setCustomerDatas();
        await this.setCustomerAddresses();
        this.selectFieldOptions.customerShippingAddresses = this.deleteIdenticalAddresses(this.selectFieldOptions.customerShippingAddresses);
        this.selectFieldOptions.customerBillingAddresses = this.deleteIdenticalAddresses(this.selectFieldOptions.customerBillingAddresses);
        this.selectFieldOptions.customerShippingAddresses.push({ id: '5dC]Y??9f@TmbJFv', label: 'Új címet adok meg' });
        this.selectFieldOptions.customerBillingAddresses.push({ id: '5dC]Y??9f@TmbJFv', label: 'Új címet adok meg' });
        this.shippingAddressStepper = 1;
        this.billingAddressStepper = 1;
        this.setDefaultAddresses();
      }
    }
    const response = await this.$store.dispatch('showFoxPost', 'FOXPOST_FRONTEND_AVAILABLE');
    if (response.status === this.$HTTP_OK) {
      if (response.data.value === 1) {
        this.foxPostdb = 1
      }
      if (response.data.value === 'ALL') {
        this.foxPostdb = 1
      }
      if (response.data.value === 'NONE') {
        this.foxPostdb = 0
      }
      if (response.data.value === 'OOW') {
        this.foxPostdb = 0
      }
      if (response.data.value === 'IW') {
        this.foxPostdb = 1
      }
    }
    window.addEventListener('message', await this.receiveMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message',this.receiveMessage)
  },
  data() {
    return {
      foxPostdb: '',
      foxpostPhoneNumber: '',
      foxpostPhone: 0,
      teszt: 'v',
      loadingComponentProps: {
        loadingMessage: 'loadingMessages.INPROGRESS_FORMLOADING',
      },
      isFormLoading: true,
      isUserCanSeeTheForm: true,
      VStepper: 1,
      VStepperHeader: [
        { step: 1, title: 'stepper.generalDetails', isDivider: false },
        { step: 2, title: 'stepper.deviceDetails', isDivider: false },
        { step: 3, title: 'stepper.customerDetails', isDivider: false },
        { step: 4, title: 'stepper.confirmation', isDivider: false },
        { step: 5, title: 'stepper.done', isDivider: false },
      ],
      acceptGeneralInfos: false,
      deviceFormIsValid: false,
      customerFormIsValid: false,
      requestSuccessSend: false,
      // igény leadási form
      serviceRequestForm: {
        formType: 3,
        processType: 1,
        prevCrmRequestId: null,
        prevGsmWorkSheetId: null,
        sourceSystem: null,
        externalId: null,
        serviceRequest: {
          partnerWorksheetNumber: null,
          prevCrmRequestId: null,
          prevGsmWorkSheetId: null,
          availableServiceId: 26,
          brand: {},
          productGroup: {},
          productTypeFull: null,
          productType: {},
          uniqueIdentifierIn1: null,
          uniqueIdentifierIn2: null,
          uniqueIdentifierIn3: null,
          warrantyType: null,
          dateOfPurchase: null,
          errorDescription: '',
          networkLock: {},
          mobilProvider: {},
          insuranceNumber: '',
          rmaNumber: '',
          accessories: null,
          paymentMethod: {
            id: null,
            lang: null,
            name: null,
          },
          deductible: null,
          isLoan: null,
          otherComment: '',
        },
        customer: {
          id: null,
          customerType: this.$store.state.authModule.user ? this.$store.state.authModule.user.profile.customerType : 'B2C',
          crmNamePrefixId: null,
          firstName: '',
          middleName: null,
          lastName: '',
          email: '',
          phoneNumber: '',
          password: '',
        },
        deliveryInAddress: {
          id: null,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
          isCompany: 0,
        },
        deliveryOutAddress: {},
        billingAddress: {
          id: null,
          isCompany: 0,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
        },
        gdpr: false,
        termsAndCond: false,
        generalInfos: true,
        accountRegistration: null,
        isAutomaticQuotationAccepting: null,
        isVipService: null,
        isAcceptanceOfUsedParts: null,
        calculatedRepairPrice: null,
        calcDataId: null,
      },
      attachment: {
        invoice: null,
        warrantyCard: null,
        evidence: null,
      },
      attachmentChecker: {
        visible: false,
        response: {
          error: false,
          data: [],
          message: 'ERROR_MESSAGES.FILE_NOT_VALID',
        },
      },
      // típuskereséshez
      typeSearch: '',
      // szállítási cím
      deliveryInAddressString: '',
      deliveryInAddressForm: false,
      deliveryInAddressModal: false,
      countrySearch: '',
      countrySearchIsLoading: false,
      postalCodeSearch: '',
      postalCodeSearchIsLoading: false,
      citySearch: '',
      citySearchIsLoading: false,
      streetSearch: '',
      streetSearchIsLoading: false,
      streetIdeiglenes: '',
      deliveryInAddressBackup: null,
      // számlázási cím
      billingAddressString: '',
      billingAddressForm: false,
      billingAddressModal: false,
      postalCodeSearchInvoice: '',
      postalCodeSearchIsLoadingInvoice: false,
      citySearchInvoice: '',
      citySearchIsLoadingInvoice: false,
      streetSearchInvoice: '',
      streetSearchIsLoadingInvoice: false,
      streetIdeiglenesInvoice: '',
      billingAddressBackup: null,
      // számlázási cím megegyezik a szállítási címmel
      billingAddressIsEqualdeliveryInAddress: false,
      // user által megadott címek kezeléséhez
      customerShippingAddress: {},
      customerBillingAddress: {},
      shippingAddressStepper: 1,
      billingAddressStepper: 2,
      brand: {},
      selectedProductGroup: {},
      legalText: '',
      deliveryMethod: null,
      foxpostAddress: '',
      foxpostData: [],
      step: 2,
      show1: false,
      mask: '#########',
      userPhone: '###########',
      taxNumberMaskHU: '########-#-##',
      imeiMask: '###############',
      modal: false,
      yearPickerModal: false,
      dateTextMask: '####-##-##',
      contactFormIsValid: false,
      contactFormCheckbox: false,
      imeiHint: '',
      maxDate: null,
      availableCustomerTypes: [],
      loadingDialog: {
        visible: false,
        message: 'loadingMessages.INPROGRESS_CONTACT_FORM',
      },
      responseDialog: {
        visible: false,
        response: {
          error: false,
          data: [],
          message: 'SUCCESS_MESSAGES.REQUEST_SEND',
        },
      },
      noFileDialog: {
        visible: false,
        response: {
          error: false,
          data: [],
          message: 'ERROR_MESSAGES.ONE_FILE_IS_REQUIRED',
        },
      },
      inputRules: {
        acceptInformation: [v => !!v || this.$t('inputMessages.acceptInformation')],
        generalRules: [v => !!v || this.$t('inputMessages.requiredField')],
        lenghtRules: [v => v.length <= 3000 || this.$t('inputMessages.maxCharacter'),
          v => !!v || this.$t('inputMessages.requiredField')],
        emailRules: [
          v => !!v || this.$t('inputMessages.requiredField'),
          v => !(/\s/.test(v)) || this.$t('inputMessages.emailNoSpace'),
          v => /.+@.+\..+/.test(v) || this.$t('inputMessages.emailMatch'),
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('inputMessages.wrongCharacter'),
        ],
        phoneRules: [
          v => /^[0-9\-]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        gdprRules: [
          v => !!v || this.$t('inputMessages.requiredPrivacy'),
        ],
        termAndCondRules: [
          v => !!v || this.$t('inputMessages.requiredTermsAndCond'),
        ],
        maxFileSize: [
          v => !v || v.size < 5000000 || this.$t('inputMessages.maxFileSizeRule'),
        ],
        maxFileSizeOptional: [
          v => !v || v.size < 5000000 || this.$t('inputMessages.maxFileSizeRule'),
        ],
        requiredFile: [
          v => !v || v.size < 5000000 || this.$t('inputMessages.maxFileSizeRule'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        imeiRule: [
          v => !!v || this.$t('inputMessages.requiredField'),
          v => /^[0-9]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
          v => (v && v.length === 15) || this.$t('inputMessages.minLenght15'),
        ],
        snRule: [
          v => !!v || this.$t('inputMessages.requiredField'),
          v => /^[a-zA-Z0-9\-\/\\]*$/.test(v) || this.$t('inputMessages.snRules'),
          v => (v && v.length < 23) || this.$t('inputMessages.minLenght15'),
        ],
        selectRules: [
          v => !!v || this.$t('inputMessages.requiredField'),
          v => ( v && v.id > 0 ) || this.$t('inputMessages.requiredField'),
        ],
        purchaseDateRule: [
          v => !!v || this.$t('inputMessages.purchaseDateReq'),
          v => this.purchaseDateIsOK(v) || `${this.$t('inputMessages.purchaseDateMax')} ${this.maxDate}!`,
        ],
        insuranceNumberRule: [
          v => !!v || this.$t('inputMessages.requiredField'),
          v => /^[a-zA-Z0-9./]*$/.test(v) || this.$t('inputMessages.insuranceNumberRule'),
        ],
        noSpecialCharAndReq: [
          (v) => !!v || this.$t('inputMessages.requiredField'),
          (v) => /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.requiredFieldNoSpecialChar'),
        ],
        noSpecialChar: [
          (v) => {
            return /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.noSpecialChar')
          },
        ],
      },
      selectFieldOptions: {
        brands: [],
        productGroups: [],
        productTypes: [],
        warrantyType: [],
        networkLock: [],
        paymentMethods: [],
        /* garancia: this.$store.state.selectfields.warrantyType,
        garanciaEN: this.$store.state.selectfields.warrantyTypeEN,
        garanciaDE: this.$store.state.selectfields.warrantyTypeDE,
        networkLock: this.$store.state.selectfields.simLock,
        networkLockEN: this.$store.state.selectfields.simLockEN,
        networkLockDE: this.$store.state.selectfields.simLockDE,*/
        countries: [
          { label: 'Magyarország', countryCode: 'HUN' },
        ],
        countriesEN: [
          { label: 'Hungary', countryCode: 'HUN' },
        ],
        countriesDE: [
          { label: 'Ungarn', countryCode: 'HUN' },
        ],
        postalCodes: [
          { header: this.$t('inputMessages.startTypingPostalCode') },
        ],
        cities: [
          { header: this.$t('inputMessages.startTypingCity') },
        ],
        streets: [
          { header: this.$t('inputMessages.startTypingStreet') },
        ],
        customerShippingAddresses: [],
        customerBillingAddresses: [],
        // számlázási címhez a selectek
        postalCodesInvoice: [
          { header: this.$t('inputMessages.startTypingPostalCode') },
        ],
        citiesInvoice: [
          { header: this.$t('inputMessages.startTypingCity') },
        ],
        streetsInvoice: [
          { header: this.$t('inputMessages.startTypingStreet') },
        ],
      },
      pickUpMinDate: null,
      pickUpMaxDate: null,
      datePickerModal: false,
      pickUpDatePickerModal: false,
      noRegPopUp: false,
      showBell: true,
      showAcc: false,
    };
  },
  methods: {
    checkAddress() {
      if (this.showFoxpost()) {
        if (this.customerShippingAddress.id !== '5dC]Y??9f@TmbJFv' && getToken()) {
          return false
        } else {
          return !(this.deliveryInAddressForm && this.serviceRequestForm.deliveryInAddress.publicPlaceType.length > 0)
        }
      } else {
        return !(this.deliveryInAddressForm && this.serviceRequestForm.deliveryInAddress.publicPlaceType.length > 0)
      }
    },
    getToken,
    foxpostPhoneNumberCheck() {
      if (getToken()) {
        if (this.foxpostPhone === 1) {
          if (this.foxpostPhoneNumber.length >= 11) {
            const check = this.foxpostPhoneNumber.toString().slice(2, 4);
            if (['20', '30', '31', '70', '50', '51'].includes(check)) {
              return true
            } else {
              return false
            }
          }
          else {
            return false
          }
        } else {
          const check = this.foxpostPhoneNumber.toString().slice(2, 4);
          if (['20', '30', '31', '70', '50', '51'].includes(check)) {
            return true
          } else {
            return false
          }
        }
      } else {
        if (this.foxpostPhoneNumber.length >= 9) {
          const check = this.foxpostPhoneNumber.toString().slice(0, 2);
          if (['20', '30', '31', '70', '50', '51'].includes(check)) {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      }
    },
    async phoneNumberCheck() {
      if(getToken()) {
        console.log(String(this.serviceRequestForm.customer.phoneNumber).slice(2,4))
        const check = String(this.serviceRequestForm.customer.phoneNumber).slice(2,4);
        console.log(check);
        if(['20','30','31','70','50','51'].includes(check)) {
          this.foxpostPhone = 0
          this.foxpostPhoneNumber = this.serviceRequestForm.customer.phoneNumber
        }
        else {
          this.foxpostPhone = 1
        }
      } else {
        const check = String(this.serviceRequestForm.customer.phoneNumber.slice(0,2));
        if(['20','30','31','70','50','51'].includes(check)) {
          this.foxpostPhone = 0
          this.foxpostPhoneNumber = this.serviceRequestForm.customer.phoneNumber
        }
        else {
          this.foxpostPhone = 1
        }
      }
    },
    screenCheck() {
      const height = window.screen.availHeight
      if (height >=  1180) {
        return '900'
      }
      else {
        return '600'
      }
    },
    setFoxpostData() {
      let deliveryData = {
        category: 'automata',
        delivererId: 6,
        fixPointDeliveryIn: this.foxpostData.operator_id,
        fixPointDeliveryOut: this.foxpostData.operator_id,
        parcelUserPhone: this.foxpostPhoneNumber
      }
      this.serviceRequestForm['deliveryData'] = deliveryData
    },
    showFoxpost() {
      if (this.foxPostdb === 1) {
        if (this.serviceRequestForm.serviceRequest.brand.id === 2) {
          return false
        } else {
          if (this.serviceRequestForm.serviceRequest.productGroup.id === 1) {
            return true
          }
          if (this.serviceRequestForm.serviceRequest.productGroup.id === 2) {
            return true
          } else {
            return false
          }
        }
      } else {
        return false
      }
    },
    async receiveMessage (event) {
      let apt = null;
      apt = JSON.parse(event.data);
      if (apt != null) {
        this.foxpostAddress = apt.address
        this.foxpostData = apt
        this.deliveryMethod = 2;
        await this.phoneNumberCheck()
        this.shippingAddressStepper = 4
        console.log(this.foxpostData);
      }
    },
    /*async randomImei(type) {
      const randomIdentifier = await this.$store.dispatch('randomImeiGenerator');
      // console.log(`%crandomIdentifier: ${randomIdentifier} - hossz: ${randomIdentifier.toString().length}`, 'background-color: #00cec9; color: #ffffff; font-size: 12px;');
      if (type === 1) {
        this.serviceRequestForm.serviceRequest.uniqueIdentifierIn1 = randomIdentifier;
      } else if (type === 2) {
        this.serviceRequestForm.serviceRequest.uniqueIdentifierIn2 = randomIdentifier;
      } else if (type === 3) {
        this.serviceRequestForm.serviceRequest.uniqueIdentifierIn3 = randomIdentifier;
      }
    },*/
    async CheckIsUserCanSeeTheForm() {
      if (this.$store.state.authModule.isAuthenticated) {
        if (this.$store.state.authModule.user) {
          const inc = this.availableCustomerTypes.findIndex(item => {
            return item.code === this.$store.state.authModule.user.profile.customerType;
          });
          // console.log('inc: ', inc);
          return inc > -1;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    setMaxAvailableDateOfPurchase() {
      const now = new Date();
      // const date = dayjs(now).subtract(3, 'day');
      this.maxDate = now.toISOString().substr(0, 10);
    },
    setDefaultCountry() {
      if (this.$i18n.locale === 'hu-HU') {
        // this.serviceRequestForm.serviceRequest.warrantyType = this.selectFieldOptions.warrantyType[0];
        this.serviceRequestForm.deliveryInAddress.country = this.selectFieldOptions.countries[0];
        this.serviceRequestForm.billingAddress.country = this.selectFieldOptions.countries[0];
      } else if (this.$i18n.locale === 'de-DE') {
        // this.serviceRequestForm.serviceRequest.warrantyType = this.selectFieldOptions.warrantyTypeDE[0];
        this.serviceRequestForm.deliveryInAddress.country = this.selectFieldOptions.countriesDE[0];
        this.serviceRequestForm.billingAddress.country = this.selectFieldOptions.countriesDE[0];
      } else {
        // this.serviceRequestForm.serviceRequest.warrantyType = this.$store.state.selectfields.warrantyTypeEN[0];
        this.serviceRequestForm.deliveryInAddress.country = this.selectFieldOptions.countriesEN[0];
        this.serviceRequestForm.billingAddress.country = this.selectFieldOptions.countriesEN[0];
      }
      // this.serviceRequestForm.serviceRequest.networkLock = this.selectFieldOptions.networkLock[4];
    },
    async setServiceRequestFormCountry() {
      if (this.$i18n.locale === 'hu-HU') {
        this.serviceRequestForm.deliveryInAddress.country = this.selectFieldOptions.countries[0];
        this.serviceRequestForm.billingAddress.country = this.selectFieldOptions.countries[0];
      } else if (this.$i18n.locale === 'de-DE') {
        this.serviceRequestForm.deliveryInAddress.country = this.selectFieldOptions.countriesDE[0];
        this.serviceRequestForm.billingAddress.country = this.selectFieldOptions.countriesDE[0];
      } else {
        this.serviceRequestForm.deliveryInAddress.country = this.selectFieldOptions.countriesEN[0];
        this.serviceRequestForm.billingAddress.country = this.selectFieldOptions.countriesEN[0];
      }
    },
    async setCustomerDatas() {
      this.serviceRequestForm.customer.firstName = this.$store.state.authModule.user.profile.firstName;
      this.serviceRequestForm.customer.lastName = this.$store.state.authModule.user.profile.lastName;
      this.serviceRequestForm.customer.email = this.$store.state.authModule.user.profile.email;
      this.serviceRequestForm.customer.phoneNumber = this.$store.state.authModule.user.profile.phone;
      this.serviceRequestForm.customer.id = getUid();
    },
    setCustomerAddresses() {
      this.$store.state.authModule.user.addresses.forEach((item, index) => {
        let temp = {};
        if (item.addressType.id === 1) {
          temp.id = item.id;
          temp.country = {
            countryCode: item.countryCode,
            label: item.countryLabel,
          };
          temp.city = item.city;
          temp.postalCode = item.zipCode;
          temp.publicPlaceName = item.publicPlaceName;
          temp.publicPlaceType = item.publicPlaceType;
          temp.streetNumber = item.houseNumber;
          temp.other = item.otherAddressInfo;
          temp.label = `${item.zipCode} ${item.city}, ${item.publicPlaceName} ${item.publicPlaceType} ${item.houseNumber}`;
          temp.billingName = '';
          temp.taxNumber = null;
          temp.accountNumber = null;
          temp.isDefault = item.isDefault;
          temp.isCompany = item.isCompany;
          this.selectFieldOptions.customerShippingAddresses.push(temp);
        } else if (item.addressType.id === 2) {
          temp.id = item.id;
          temp.country = {
            countryCode: item.countryCode,
            label: item.countryLabel,
          };
          temp.city = item.city;
          temp.postalCode = item.zipCode;
          temp.publicPlaceName = item.publicPlaceName;
          temp.publicPlaceType = item.publicPlaceType;
          temp.streetNumber = item.houseNumber;
          temp.other = item.otherAddressInfo;
          temp.billingName = item.billingName;
          temp.taxNumber = null;
          temp.accountNumber = null;
          temp.label = `${item.billingName} - ${item.zipCode} ${item.city}, ${item.publicPlaceName} ${item.publicPlaceType} ${item.houseNumber}`;
          temp.isDefault = item.isDefault;
          temp.isCompany = item.isCompany;
          this.selectFieldOptions.customerBillingAddresses.push(temp);
        }
      });
      // this.selectFieldOptions.customerShippingAddresses.push({id: '5dC]Y??9f@TmbJFv', label: 'Új címet adok meg'});
      // this.selectFieldOptions.customerBillingAddresses.push({id: '5dC]Y??9f@TmbJFv', label: 'Új címet adok meg'});
    },
    deleteIdenticalAddresses(array) {
      const goodArray = array;
      // console.log('eredeti:');
      // console.table(goodArray);
      // console.log('START --------------');
      goodArray.sort((a, b) => ((a.isDefault === b.isDefault) ? 0 : a.isDefault ? -1 : 1));
      for (let a = 0; a < goodArray.length; a++) {
        const item = goodArray[a];
        // console.log(`${a}. item: `, item);
        if (item) {
          // console.log(`${a}. item.isDefault: `, item.isDefault);
          for (let b = a + 1; b < goodArray.length; b++) {
            const item2 = goodArray[b];
            // console.log(`${b}. item2: `, item2);
            if (item2) {
              // console.log(`${b}. item2.isDefault: `, item2.isDefault);
              if (item.billingName === item2.billingName
                && item.city === item2.city
                && item.houseNumber === item2.houseNumber
                && item.publicPlaceName === item2.publicPlaceName
                && item.publicPlaceType === item2.publicPlaceType
                && item.zipCode === item2.zipCode) {
                // console.log(`${a}. és ${b}. megegyezik - Törlés ${b}.`);
                delete goodArray[b];
              }
            }
          }
        }
      }
      // console.log('END --------------');
      const goodArrayFiltered = goodArray.filter((e) => e != null);
      // console.log('átalakított:');
      // console.table(goodArrayFiltered);
      // console.log('%cend delete', 'color: #FFFFFF; font-size: 12px; background: #6c5ce7;');
      return goodArrayFiltered;
    },
    setDefaultAddresses() {
      const shippingDefaultIndex = this.selectFieldOptions.customerShippingAddresses.findIndex((item) => {
        return item.isDefault;
      });
      if (shippingDefaultIndex < 0) {
        this.customerShippingAddress = this.selectFieldOptions.customerShippingAddresses[0];
      } else {
        this.customerShippingAddress = this.selectFieldOptions.customerShippingAddresses[shippingDefaultIndex];
      }
      const billingDefaultIndex = this.selectFieldOptions.customerBillingAddresses.findIndex((item) => {
        return item.isDefault;
      });
      if (billingDefaultIndex < 0) {
        this.customerBillingAddress = this.selectFieldOptions.customerBillingAddresses[0];
      } else {
        this.customerBillingAddress = this.selectFieldOptions.customerBillingAddresses[billingDefaultIndex];
      }
    },
    setProcessType() {
      this.serviceRequestForm.processType = 1;
      if (getToken() && getUid()) {
        if (!this.serviceRequestForm.deliveryInAddress.id && !this.serviceRequestForm.billingAddress.id) {
          this.serviceRequestForm.processType = 4;
        } else if (!this.serviceRequestForm.billingAddress.id) {
          this.serviceRequestForm.processType = 3;
        } else if (!this.serviceRequestForm.deliveryInAddress.id) {
          this.serviceRequestForm.processType = 2;
        } else {
          this.serviceRequestForm.processType = 5;
        }
      }
    },
    nextStep() {
      this.VStepper += 1;
    },
    prevStep() {
      this.VStepper -= 1;
    },
    /*
    BEKÜLDÉSHEZ SZÜKSÉGES METÓDUSOK
     */
    useNoRegPopupBeforeSend() {
      if (this.$store.state.authModule.user) {
        this.sendContactForm();
      } else if (this.serviceRequestForm.accountRegistration === 'yes') {
        this.sendContactForm();
      } else {
        this.openNoRegDialog();
      }
    },
    async sendContactForm() {
      this.closeNoRegToContinueSend();
      if(this.documentCheck()) {
        this.openLoadingDialog();
        this.serviceRequestForm.serviceRequest.insuranceNumber = this.serviceRequestForm.serviceRequest.insuranceNumber.toUpperCase();
        const smResponse = await this.setSimLockAndMobilProvider(this.serviceRequestForm.serviceRequest.insuranceNumber);
        if (smResponse) {
          if (this.serviceRequestForm.serviceRequest.dateOfPurchase === '0000-00-00') {
            this.responseDialog.response.error = true;
            this.responseDialog.response.message = 'ERROR_MESSAGES.INVALID_DATE';
            this.controlDialogs();
          } else {
            // CÍMKEZELÉS START
            if (this.$store.state.authModule.isAuthenticated) {
              // const addressResponse = await this.callFindCustomerCorrespondingAddresses(this.serviceRequestForm.deliveryInAddress);
              this.serviceRequestForm.deliveryOutAddress = await this.findCustomerCorrespondingAddress(this.serviceRequestForm.deliveryInAddress, 3, true);
              this.serviceRequestForm.billingAddress = await this.findCustomerCorrespondingAddress(this.serviceRequestForm.deliveryInAddress, 2, true);
            } else {
              this.serviceRequestForm.deliveryOutAddress = this.serviceRequestForm.deliveryInAddress;
              this.serviceRequestForm.billingAddress = this.serviceRequestForm.deliveryInAddress;
              this.serviceRequestForm.billingAddress.billingName = `${this.serviceRequestForm.customer.lastName} ${this.serviceRequestForm.customer.firstName}`;
            }
            // CÍMKEZELÉS END
            // const smResponse = await this.setSimLockAndMobilProvider(this.serviceRequestForm.serviceRequest.insuranceNumber);
            // console.log('networkLock: ', this.serviceRequestForm.serviceRequest.networkLock);
            // console.log('mobilProvider: ', this.serviceRequestForm.serviceRequest.mobilProvider);
            this.setProcessType();
            if (this.serviceRequestForm.accountRegistration === 'no' || this.serviceRequestForm.accountRegistration === null) {
              this.serviceRequestForm.customer.password = null;
            }
            // Purchase Price ellenőrzése
            if (this.serviceRequestForm.serviceRequest.purchasePrice === '') {
              this.serviceRequestForm.serviceRequest.purchasePrice = null;
            }
            if (this.serviceRequestForm.serviceRequest.purchasePrice) {
              if (this.serviceRequestForm.serviceRequest.purchasePrice.length < 1) {
                this.serviceRequestForm.serviceRequest.purchasePrice = null;
              }
            }
            // duplikáció ellenőrzése
            const duplicationResponse = await this.$store.dispatch('CheckDuplication',
              {
                email: this.serviceRequestForm.customer.email,
                identifiers: [
                  this.serviceRequestForm.serviceRequest.uniqueIdentifierIn1,
                  this.serviceRequestForm.serviceRequest.uniqueIdentifierIn2,
                  this.serviceRequestForm.serviceRequest.uniqueIdentifierIn3,
                ]
              }
            );
            if (duplicationResponse !== 400) {
              if ( this.deliveryMethod === 1 || !this.deliveryMethod ) {
                delete this.serviceRequestForm['deliveryData']
              }
              if (this.deliveryMethod === 2) {
                this.setFoxpostData()
                if (this.serviceRequestForm.deliveryData.parcelUserPhone.length <= 9) {
                  this.serviceRequestForm.deliveryData.parcelUserPhone = '36' + this.serviceRequestForm.deliveryData.parcelUserPhone;
                }
              }
              if (this.serviceRequestForm.customer.phoneNumber.length <= 9) {
                this.serviceRequestForm.customer.phoneNumber = '36' + this.serviceRequestForm.customer.phoneNumber;
              }
              const response = await this.$store.dispatch('sendMobilfutarRequest',
                  { form: this.serviceRequestForm, attachment: this.attachment });
              // console.log('sendContactForm response: ', response);
              if (response.status === 200) {
                this.responseDialog.response.error = false;
                this.responseDialog.response.message = 'SUCCESS_MESSAGES.REQUEST_SEND';
                this.requestSuccessSend = true;
              } else if (response.status === 201) {
                this.responseDialog.response.error = false;
                this.responseDialog.response.message = 'SUCCESS_MESSAGES.REQUEST_SEND';
                this.requestSuccessSend = true;
              } else if (response.status === 422) {
                this.responseDialog.response.error = true;
                this.responseDialog.response.message = 'ERROR_MESSAGES.USER_EXISTS';
              } else if (response.status === 403) {
                this.responseDialog.response.error = true;
                this.responseDialog.response.message = 'ERROR_MESSAGES.USER_EXISTS';
              } else if(response.status === 405) {
                this.responseDialog.response.error = true;
                this.responseDialog.response.message = 'ERROR_MESSAGES.REQUEST_SEND_1';
              } else {
                this.responseDialog.response.error = true;
                this.responseDialog.response.message = 'ERROR_MESSAGES.REQUEST_SEND_2';
              }
            } else {
              this.responseDialog.response.error = true;
              this.responseDialog.response.message = 'ERROR_MESSAGES.DUPLICATION';
            }
            this.controlDialogs();
          }
        }
      } else {
        this.openNoFileDialog();
      }
    },
    openLoadingDialog() {
      this.loadingDialog.visible = true;
    },
    closeLoadingDialog() {
      this.loadingDialog.visible = false;
    },
    setupResponseDialog(message) {
      this.responseDialog.response.message = message;
    },
    openResponseDialog() {
      this.responseDialog.visible = true;
    },
    closeResponseDialog() {
      this.responseDialog.visible = false;
      if (!this.responseDialog.response.error) {
        this.$router.push('/');
      }
    },
    openNoFileDialog() {
      this.noFileDialog.visible = true;
    },
    closeNoFileDialog() {
      this.noFileDialog.visible = false;
    },
    closeNotValidPdfDialog() {
      this.attachmentChecker.visible = false;
    },
    controlDialogs() {
      setTimeout(this.closeLoadingDialog, 3000);
      setTimeout(this.nextStep, 3100);
    },
    openNoRegDialog() {
      this.noRegPopUp = true;
    },
    closeNoRegPopup() {
      this.noRegPopUp = false;
      this.VStepper = 3;
    },
    closeNoRegToContinueSend() {
      this.noRegPopUp = false;
    },
    /**
     *
     * Ellenörzi, hogy számla vagy garjegy van-e feltöltve
     *
     **/
    documentCheck() {
      /* let validForm = false;
      if (Array.isArray(this.attachment.invoice) || this.attachment.invoice === null) {
        validForm = !(Array.isArray(this.attachment.warrantyCard) || this.attachment.warrantyCard === null);
      } else {
        validForm = true;
      }
      return validForm; */
      return true;
    },
    clearFile(name) {
      if (name === 'invoice') {
        this.attachment.invoice = null;
      } else if (name === 'warrantyCard') {
        this.attachment.warrantyCard = null;
      }
    },
    /**
     * SZÁLLÍTÁSI CÍM MEGADÁSÁHOZ FGV-EK
     */
    async getPostalCodesByCountry(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.serviceRequestForm.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.postalCodes = response.data;
        this.postalCodeSearchIsLoading = false;
        this.selectFieldOptions.postalCodes.unshift({ header: this.$t('inputMessages.startTypingPostalCode') });
      }
    },
    async getCityByPostalCode() {
      let postalcode = 0;
      if (this.serviceRequestForm.deliveryInAddress.postalCode && this.serviceRequestForm.deliveryInAddress.postalCode.address) {
        postalcode = this.serviceRequestForm.deliveryInAddress.postalCode.address.postalCode;
        this.serviceRequestForm.deliveryInAddress.postalCode = postalcode;
      }
      const response = await HEREGETCITYBYPOSTALCODE(this.serviceRequestForm.deliveryInAddress.postalCode, this.serviceRequestForm.deliveryInAddress.country.countryCode);
      if (!response.error) {
        if (!response.data[0].address.city) {
          this.selectFieldOptions.cities = [];
        } else {
          this.selectFieldOptions.cities = response.data;
          this.citySearchIsLoading = false;
          this.selectFieldOptions.cities.unshift({ header: this.$t('inputMessages.startTypingCity') });
        }
      }
    },
    async getPostalCodeByCity(value) {
      if (this.serviceRequestForm.deliveryInAddress.postalCode === null) {
        this.serviceRequestForm.deliveryInAddress.postalCode = ''
      }
      const response = await HEREGETPOSTALCODESBYCITY(value, this.serviceRequestForm.deliveryInAddress.postalCode, this.serviceRequestForm.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.cities = response.data;
        this.citySearchIsLoading = false;
        this.selectFieldOptions.cities.unshift({ header: this.$t('inputMessages.startTypingCity') });
      }
    },
    async getStreetByPostalCode(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value, this.serviceRequestForm.deliveryInAddress.city, this.serviceRequestForm.deliveryInAddress.postalCode, this.serviceRequestForm.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.streets = response.data;
        this.streetSearchIsLoading = false;
        this.selectFieldOptions.streets.unshift({ header: this.$t('inputMessages.startTypingStreet') });
      }
    },
    async onPostalCodeSelect() {
      await this.getCityByPostalCode();
      if (this.selectFieldOptions.cities.length === 2) {
        this.serviceRequestForm.deliveryInAddress.city = this.selectFieldOptions.cities[1].address.city;
        this.citySearch = this.selectFieldOptions.cities[1].address.city;
      }
      this.serviceRequestForm.deliveryInAddress.publicPlaceName = '';
      this.serviceRequestForm.deliveryInAddress.publicPlaceType = '';
      this.serviceRequestForm.deliveryInAddress.streetNumber = '';
      this.streetIdeiglenes = '';
      this.streetSearch = null;
    },
    onPublicPlaceSelect() {
      let street = '';
      let type = '';
      if (this.streetIdeiglenes && this.streetIdeiglenes.length > 0) {
        // console.log('1');
        street = this.streetIdeiglenes.substring(0, this.streetIdeiglenes.lastIndexOf(' '));
        type = this.streetIdeiglenes.substring(this.streetIdeiglenes.lastIndexOf(' '), this.streetIdeiglenes.length + 1).trim();
        if (this.streetIdeiglenes.lastIndexOf(' ') > 0) {
          // console.log('1a');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        } else {
          // console.log('1b');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = this.streetIdeiglenes;
          type = this.selectFieldOptions.streets[1].address.street.substring(this.selectFieldOptions.streets[1].address.street.lastIndexOf(' '), this.selectFieldOptions.streets[1].address.street.length + 1).trim();
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        }
      } else if (this.streetIdeiglenes && this.streetIdeiglenes.address) {
        // console.log('2');
        street = this.streetIdeiglenes.address.street.substring(0, this.streetIdeiglenes.address.street.lastIndexOf(' '));
        type = this.streetIdeiglenes.address.street.substring(this.streetIdeiglenes.address.street.lastIndexOf(' '), this.streetIdeiglenes.address.street.length + 1).trim();
        if (this.streetIdeiglenes.address.street.lastIndexOf(' ') > 0) {
          // console.log('2a');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        } else {
          console.log('2b');
        }
      }
      //
      //
    },
    preventEnter() {
      //
    },
    clearAllAddressInputs() {
      this.selectFieldOptions.postalCodes = [
        { header: this.$t('inputMessages.startTypingPostalCode') },
      ];
      this.selectFieldOptions.cities = [
        { header: this.$t('inputMessages.startTypingCity') },
      ];
      this.selectFieldOptions.streets = [
        { header: this.$t('inputMessages.startTypingStreet') },
      ];
      this.postalCodeSearch = '';
      this.postalCodeSearchIsLoading = false;
      this.citySearch = '';
      this.citySearchIsLoading = false;
      this.streetSearch = '';
      this.streetSearchIsLoading = false;
      this.streetIdeiglenes = '';
      this.serviceRequestForm.deliveryInAddress.postalCode = '';
      this.serviceRequestForm.deliveryInAddress.city = '';
      this.serviceRequestForm.deliveryInAddress.publicPlaceType = '';
      this.serviceRequestForm.deliveryInAddress.publicPlaceName = '';
      this.serviceRequestForm.deliveryInAddress.streetNumber = '';
    },
    saveDeliveryInAddressData() {
      this.deliveryInAddressString = this.serviceRequestForm.deliveryInAddress.postalCode + ' '
          + this.serviceRequestForm.deliveryInAddress.city + ', '
          + this.serviceRequestForm.deliveryInAddress.publicPlaceName + ' '
          + this.serviceRequestForm.deliveryInAddress.publicPlaceType + ' '
          + this.serviceRequestForm.deliveryInAddress.streetNumber;
      this.billingAddressIsEqualdeliveryInAddress = true;
      this.deliveryInAddressModal = false;
      this.shippingAddressStepper = 1;
    },
    cancelDeliveryInAddressModal() {
      this.deliveryInAddressModal = false;
    },
    /**
     * SZÁMLÁZÁSI CÍM MEGADÁSÁHOZ A FGV-EK
     **/
    async getPostalCodesByCountryInvoice(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.serviceRequestForm.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.postalCodesInvoice = response.data;
        this.postalCodeSearchIsLoadingInvoice = false;
        this.selectFieldOptions.postalCodesInvoice.unshift({ header: this.$t('inputMessages.startTypingPostalCode') });
      }
    },
    async getCityByPostalCodeInvoice() {
      let postalcode = 0;
      if (this.serviceRequestForm.billingAddress.postalCode && this.serviceRequestForm.billingAddress.postalCode.address) {
        postalcode = this.serviceRequestForm.billingAddress.postalCode.address.postalCode;
        this.serviceRequestForm.billingAddress.postalCode = postalcode;
      }
      const response = await HEREGETCITYBYPOSTALCODE(this.serviceRequestForm.billingAddress.postalCode, this.serviceRequestForm.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.citiesInvoice = response.data;
        this.citySearchIsLoadingInvoice = false;
        this.selectFieldOptions.citiesInvoice.unshift({ header: this.$t('inputMessages.startTypingCity') });
      }
    },
    async getPostalCodeByCityInvoice(value) {
      if (this.serviceRequestForm.billingAddress.postalCode === null) {
        this.serviceRequestForm.billingAddress.postalCode = ''
      }
      const response = await HEREGETPOSTALCODESBYCITY(value, this.serviceRequestForm.billingAddress.postalCode, this.serviceRequestForm.billingAddress.country.countryCode);
      if (!response.error) {
        if (!response.data[0].address.city) {
          this.selectFieldOptions.citiesInvoice = [];
        } else {
          this.selectFieldOptions.citiesInvoice = response.data;
          this.citySearchIsLoadingInvoice = false;
          this.selectFieldOptions.citiesInvoice.unshift({ header: this.$t('inputMessages.startTypingCity') });
        }
      }
    },
    async getStreetByPostalCodeInvoice(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value, this.serviceRequestForm.billingAddress.city, this.serviceRequestForm.billingAddress.postalCode, this.serviceRequestForm.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.streetsInvoice = response.data;
        this.streetSearchIsLoadingInvoice = false;
        this.selectFieldOptions.streetsInvoice.unshift({ header: this.$t('inputMessages.startTypingStreet') });
      }
    },
    async onPostalCodeSelectInvoice() {
      await this.getCityByPostalCodeInvoice();
      if (this.selectFieldOptions.citiesInvoice.length === 2) {
        this.serviceRequestForm.billingAddress.city = this.selectFieldOptions.citiesInvoice[1].address.city;
        this.citySearchInvoice = this.selectFieldOptions.citiesInvoice[1].address.city;
      }
      this.serviceRequestForm.billingAddress.publicPlaceName = '';
      this.serviceRequestForm.billingAddress.publicPlaceType = '';
      this.serviceRequestForm.billingAddress.streetNumber = '';
      this.streetIdeiglenesInvoice = '';
      this.streetSearchInvoice = null;
    },
    onPublicPlaceSelectInvoice() {
      let street = '';
      let type = '';
      if (this.streetIdeiglenesInvoice && this.streetIdeiglenesInvoice.length > 0) {
        street = this.streetIdeiglenesInvoice.substring(0, this.streetIdeiglenesInvoice.lastIndexOf(' '));
        type = this.streetIdeiglenesInvoice.substring(this.streetIdeiglenesInvoice.lastIndexOf(' '), this.streetIdeiglenesInvoice.length + 1).trim();
        if (this.streetIdeiglenesInvoice.lastIndexOf(' ') > 0) {
          // console.log('3a');
          this.serviceRequestForm.billingAddress.publicPlaceName = street;
          this.serviceRequestForm.billingAddress.publicPlaceType = type;
        } else {
          // console.log('3b');
          this.serviceRequestForm.billingAddress.publicPlaceName = this.streetIdeiglenesInvoice;
          type = this.selectFieldOptions.streetsInvoice[1].address.street.substring(this.selectFieldOptions.streetsInvoice[1].address.street.lastIndexOf(' '), this.selectFieldOptions.streetsInvoice[1].address.street.length + 1).trim();
          this.serviceRequestForm.billingAddress.publicPlaceType = type;
        }
      } else if (this.streetIdeiglenesInvoice && this.streetIdeiglenesInvoice.address) {
        street = this.streetIdeiglenesInvoice.address.street.substring(0, this.streetIdeiglenesInvoice.address.street.lastIndexOf(' '));
        type = this.streetIdeiglenesInvoice.address.street.substring(this.streetIdeiglenesInvoice.address.street.lastIndexOf(' '), this.streetIdeiglenesInvoice.address.street.length + 1).trim();
        if (this.streetIdeiglenesInvoice.address.street.lastIndexOf(' ') > 0) {
          // console.log('4a');
          this.serviceRequestForm.billingAddress.publicPlaceName = street;
          this.serviceRequestForm.billingAddress.publicPlaceType = type;
        } else {
          // console.log('4b');
        }
      }
    },
    clearAllAddressInputsInvoice() {
      this.selectFieldOptions.postalCodesInvoice = [
        { header: this.$t('inputMessages.startTypingPostalCode') },
      ];
      this.selectFieldOptions.citiesInvoice = [
        { header: this.$t('inputMessages.startTypingCity') },
      ];
      this.selectFieldOptions.streetsInvoice = [
        { header: this.$t('inputMessages.startTypingStreet') },
      ];
      this.postalCodeSearchInvoice = '';
      this.postalCodeSearchIsLoadingInvoice = false;
      this.citySearchInvoice = '';
      this.citySearchIsLoadingInvoice = false;
      this.streetSearchInvoice = '';
      this.streetSearchIsLoadingInvoice = false;
      this.streetIdeiglenesInvoice = '';
      this.serviceRequestForm.billingAddress.postalCode = '';
      this.serviceRequestForm.billingAddress.city = '';
      this.serviceRequestForm.billingAddress.publicPlaceType = '';
      this.serviceRequestForm.billingAddress.publicPlaceName = '';
      this.serviceRequestForm.billingAddress.streetNumber = '';
    },
    saveBillingAddressData() {
      this.billingAddressString = this.serviceRequestForm.billingAddress.billingName
          + ' - ' + this.serviceRequestForm.billingAddress.postalCode + ' '
          + this.serviceRequestForm.billingAddress.city + ', '
          + this.serviceRequestForm.billingAddress.publicPlaceName + ' '
          + this.serviceRequestForm.billingAddress.publicPlaceType + ' '
          + this.serviceRequestForm.billingAddress.streetNumber;
      this.billingAddressModal = false;
    },
    openBillingAddressModal() {
      // a meglévő szállítási címet kimentjük egy ideiglenes változóba,
      // hogy ha a user a Mégsem gombra kattint, akkor megmaradjon az előzőleg beírt cím
      this.billingAddressBackup = this.serviceRequestForm.billingAddress;
      this.billingAddressModal = true;
    },
    closeBillingAddressModalWithoutSave() {
      // Mégsem gombra kattintva visszaszerezzük az előzőleg beírt címet
      this.serviceRequestForm.billingAddress = this.billingAddressBackup;
      this.billingAddressModal = false;
      this.billingAddressBackup = null;
    },
    async getProductGroupsByBrandId() {
      const response = await this.$store.dispatch('getProductGroupsByBrandId', this.serviceRequestForm.serviceRequest.brand.id);
      if (response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    },
    async setSimLockAndMobilProvider(value) {
      // 0001896584
      const per = '/';
      const dot = '.';
      if (TNC_REGEXP.test(value)) {
        this.serviceRequestForm.serviceRequest.networkLock = { id: 2, name: 'HU-Telenor' };
        this.serviceRequestForm.serviceRequest.mobilProvider = { id: 2, name: 'Telenor' };
        this.serviceRequestForm.serviceRequest.availableServiceId = 57;
      } else if (TC_REGEXP.test(value)) {
        this.serviceRequestForm.serviceRequest.networkLock = { id: 1, name: 'HU-T-Mobile' };
        this.serviceRequestForm.serviceRequest.mobilProvider = { id: 1, name: 'Magyar Telekom' };
        this.serviceRequestForm.serviceRequest.availableServiceId = 26;
      } else if (MC_REGEXP.test(value)) {
        this.serviceRequestForm.serviceRequest.networkLock = { id: 4, name: 'HU-Open' };
        this.serviceRequestForm.serviceRequest.mobilProvider = { id: 4, name: 'Független' };
        this.serviceRequestForm.serviceRequest.availableServiceId = 59;
      } else if (AC_REGEXP.test(value)) {
        this.serviceRequestForm.serviceRequest.networkLock = { id: 4, name: 'HU-Open' };
        this.serviceRequestForm.serviceRequest.mobilProvider = { id: 4, name: 'Független' };
        this.serviceRequestForm.serviceRequest.availableServiceId = 59;
        if (value.includes(per)) {
          // console.log('ac/');
          this.serviceRequestForm.serviceRequest.availableServiceId = 63;
        }
      } else if (H_REGEXP.test(value) && value.length === 10 && value.includes(dot)) {
        // console.log('H');
        this.serviceRequestForm.serviceRequest.networkLock = { id: 5, name: 'Egyéb' };
        this.serviceRequestForm.serviceRequest.mobilProvider = { id: 5, name: 'Egyéb' };
        this.serviceRequestForm.serviceRequest.availableServiceId = 65;
      } else if (START_2DIG.test(value) && value.length === 8) {
        // console.log('2d');
        this.serviceRequestForm.serviceRequest.networkLock = { id: 5, name: 'Egyéb' };
        this.serviceRequestForm.serviceRequest.mobilProvider = { id: 5, name: 'Egyéb' };
        this.serviceRequestForm.serviceRequest.availableServiceId = 61;
      } else if (O2_INSURANCE_REGEXP.test(value) && value.length === 6) {
        this.serviceRequestForm.serviceRequest.networkLock = { id: 5, name: 'Egyéb' };
        this.serviceRequestForm.serviceRequest.mobilProvider = { id: 5, name: 'Egyéb' };
        this.serviceRequestForm.serviceRequest.availableServiceId = 97;
        console.log('O2');
      } else {
        // console.log('semmi');
        this.responseDialog.response.error = true;
        this.responseDialog.response.message = 'ERROR_MESSAGES.INSURANCE_NUMBER';
        this.closeLoadingDialog();
        this.openResponseDialog();
        this.VStepper = 2;
        this.$vuetify.goTo(0, { duration: 800, offset: 0 });
        return false;
      }
      return true;
    },
    /***
     *
     * Kárszám alapján visszaadja a networkLock értékét
     *
     * kárszámot teszteli a systemParams-ban megadott regexpekre
     * és a szabály alapján visszadja networkLock értékét
     *
     * @returns {Promise<{name: string, id: number}>}
     */
    async setSimLockByInsuranceNumber(insuranceNumber) {
      // console.log('sim - insuranceNumber: ', insuranceNumber);
      // console.log('TC_REGEXP: ', TC_REGEXP.test(insuranceNumber));
      // console.log('insuranceNumber: ', insuranceNumber);
      if (TNC_REGEXP.test(insuranceNumber)) {
        return { id: 2, name: 'HU-Telenor' };
      } else if (TC_REGEXP.test(insuranceNumber)) {
        return { id: 1, name: 'HU-T-Mobile' };
      } else if (MC_REGEXP.test(insuranceNumber)) {
        return { id: 4, name: 'HU-Open' };
      } else if (AC_REGEXP.test(insuranceNumber)) {
        return { id: 4, name: 'HU-Open' };
      }
      return { id: 5, name: 'Egyéb' };
    },
    /***
     *
     * Kárszám alapján visszaadja a mobilProvider értékét
     *
     * kárszámot teszteli a systemParams-ban megadott regexpekre
     * és a szabály alapján visszadja mobilProvider értékét
     *
     * @returns {Promise<{name: string, id: number}>}
     */
    async setMobilProviderByInsuranceNumber(insuranceNumber) {
      // console.log('mob - insuranceNumber: ', insuranceNumber);
      // console.log('TC_REGEXP: ', TC_REGEXP.test(insuranceNumber));
      // console.log('insuranceNumber: ', insuranceNumber);
      if (TNC_REGEXP.test(insuranceNumber)) {
        // console.log('Telenor');
        return { id: 2, name: 'Telenor' };
      } else if (TC_REGEXP.test(insuranceNumber)) {
        // console.log('Telekom');
        return { id: 1, name: 'Magyar Telekom' };
      } else if (MC_REGEXP.test(insuranceNumber)) {
        // console.log('Független');
        return { id: 4, name: 'Független' };
      } else if (AC_REGEXP.test(insuranceNumber)) {
        // console.log('Független');
        return { id: 4, name: 'Független' };
      }
      return { id: 5, name: 'Egyéb' };
    },
    async callShowProductGroupOfBrand(brand) {
      // console.log('callShowProductGroupOfBrand start...');
      const resp = await this.$store.dispatch('showProductGroupOfBrand', brand.id);
      // console.log('callShowProductGroupOfBrand: ', resp);
      this.selectFieldOptions.productGroups = resp.data;
    },
    async callShowForm() {
      const response = await this.$store.dispatch('showForm', 'BiztositasiSzervizgarForm');
      if (response.status === 200) {
        this.availableCustomerTypes = response.data.customerType;
        this.selectFieldOptions.warrantyType = response.data.warrantyType;
        this.selectFieldOptions.networkLock = response.data.networkLock;
        this.selectFieldOptions.brands = response.data.brand.sort((a, b) => ((a.name > b.name) ? 1 : -1));
        this.serviceRequestForm.serviceRequest.warrantyType = this.selectFieldOptions.warrantyType[0];
      }
    },
    async callShowPaymentMethodOfCustomerType() {
      const pm = this.$store.state.authModule.user ? this.$store.state.authModule.user.profile.customerType : 'B2C';
      const response = await this.$store.dispatch('showPaymentMethodOfCustomerType', pm);
      if (response.status === 200) {
        if (response.data.length === 1) {
          this.serviceRequestForm.serviceRequest.paymentMethod = response.data[0];
        } else {
          response.data.sort((a, b) => ((a.name > b.name) ? 1 : -1));
        }
        this.selectFieldOptions.paymentMethods = response.data;
      }
    },
    async callShowProductTypeOfBrand() {
      // console.log('callShowProductTypeOfBrand start...');
      const response = await this.$store.dispatch('showProductTypeOfBrand',
        { brandId: this.serviceRequestForm.serviceRequest.brand.id, productGroupId: this.serviceRequestForm.serviceRequest.productGroup.id });
      // console.log('callShowProductTypeOfBrand: ', response);
      if (response.status === 200) {
        response.data.sort((a, b) => ((a.productTypeShoppingName > b.productTypeShoppingName) ? 1 : -1));
        // ha APPLE brand.id = 2, akkor nem kell
        const benne = this.$NoDisplayNameBrands.includes(this.serviceRequestForm.serviceRequest.brand.id);
        if (benne) {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].displayName = `${response.data[i].productTypeShoppingName}`;
          }
        } else {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].displayName = `${response.data[i].productTypeShoppingName} - ${response.data[i].gsmTipusNev}`;
          }
        }
        this.selectFieldOptions.productTypes = response.data;
      }
    },
    purchaseDateIsOK(date) {
      const inputDate = dayjs(date);
      const maxD = dayjs(this.maxDate);
      const max = dayjs(maxD).add(1, 'day');
      if (date) {
        if (date.length === 10) {
          if (new Date(date).getDate()) {
            return inputDate.isBefore(dayjs(max));
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    ckeckFileSize(file) {
      if (file) {
        // console.log('if ág - file: ', file);
        return file.size < 5000000;
      }
      // console.log('file: ', file);
      return true;
    },
    pdfChecker(file, type) {
      const pdfRegexp = new RegExp('%PDF-1.[0-7]');
      // const pdfRegexp = new RegExp('^%PDF');
      const size = file.size;
      let header = '';
      let scope = this;
      let fr = new FileReader();
      fr.readAsText(file);
      fr.onload = function (evt) {
        header = evt.target.result.substr(0, 8);
        // console.log('header: ', header);
        if (pdfRegexp.test(header) && size > 0) {
          // console.log('%cOK', 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
          scope.attachmentChecker.response.error = false;
        } else {
          // console.log('%cNEM OK', 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
          scope.attachmentChecker.visible = true;
          scope.attachmentChecker.response.error = true;
          if (type === 1) {
            scope.attachment.invoice = null;
          } else {
            scope.attachment.warrantyCard = null;
          }
        }
      };
    },
  },
  watch: {
    yearPickerModal(value) {
      value && setTimeout(() => (this.$refs.yearPicker.activePicker = 'YEAR'));
    },
    postalCodeSearch(value) {
      this.postalCodeSearchIsLoading = true;
      this.getPostalCodesByCountry(value);
    },
    citySearch(value) {
       this.citySearchIsLoading = true;
       this.getPostalCodeByCity(value);
    },
    citySearchInvoice(value) {
       this.citySearchIsLoadingInvoice = true;
       this.getPostalCodeByCityInvoice(value);
    },
    streetSearch(value) {
      if (value.length >= 1) {
        this.streetSearchIsLoading = true;
        this.getStreetByPostalCode(value);
      }
    },
    postalCodeSearchInvoice(value) {
      this.postalCodeSearchIsLoading = true;
      this.getPostalCodesByCountryInvoice(value);
    },
    streetSearchInvoice(value) {
      if (value.length >= 1) {
        this.streetSearchIsLoading = true;
        this.getStreetByPostalCodeInvoice(value);
      }
    },
    billingAddressIsEqualdeliveryInAddress(value) {
      if (value) {
        this.serviceRequestForm.billingAddress.billingName = this.serviceRequestForm.customer.lastName + ' ' + this.serviceRequestForm.customer.firstName;
        this.serviceRequestForm.billingAddress.postalCode = this.serviceRequestForm.deliveryInAddress.postalCode;
        this.serviceRequestForm.billingAddress.city = this.serviceRequestForm.deliveryInAddress.city;
        this.serviceRequestForm.billingAddress.publicPlaceName = this.serviceRequestForm.deliveryInAddress.publicPlaceName;
        this.serviceRequestForm.billingAddress.publicPlaceType = this.serviceRequestForm.deliveryInAddress.publicPlaceType;
        this.serviceRequestForm.billingAddress.streetNumber = this.serviceRequestForm.deliveryInAddress.streetNumber;
        this.serviceRequestForm.billingAddress.other = this.serviceRequestForm.deliveryInAddress.other;
        this.saveBillingAddressData();
      } else {
        this.clearAllAddressInputsInvoice();
        this.serviceRequestForm.billingAddress.billingName = '';
        this.serviceRequestForm.billingAddress.taxNumber = '';
        this.serviceRequestForm.billingAddress.other = '';
        this.billingAddressString = '';
      }
    },
    'serviceRequestForm.serviceRequest.brand': function (newVal) {
      // console.log('brand newVal: ', newVal);
      this.serviceRequestForm.serviceRequest.productGroup = {};
      this.serviceRequestForm.serviceRequest.productTypeFull = null;
      this.serviceRequestForm.serviceRequest.productType = {};
      this.selectFieldOptions.productGroups = [];
      this.callShowProductGroupOfBrand(newVal);
    },
    'serviceRequestForm.serviceRequest.productGroup': function (newVal) {
      // console.log('productGroup newVal: ', newVal);
      this.serviceRequestForm.serviceRequest.uniqueIdentifierIn1 = null;
      this.serviceRequestForm.serviceRequest.uniqueIdentifierIn2 = null;
      this.serviceRequestForm.serviceRequest.uniqueIdentifierIn3 = null;
      this.serviceRequestForm.serviceRequest.productTypeFull = null;
      this.selectFieldOptions.productTypes = [];
      // this.selectedProductGroup = this.serviceRequestForm.serviceRequest.productGroup.find(item => item.productGroup.id === newVal.id);
      if (newVal.id) {
        this.callShowProductTypeOfBrand();
      }
    },
    'serviceRequestForm.serviceRequest.productTypeFull': function (newVal) {
      // console.log('productTypeFull newVal: ', newVal);
      this.serviceRequestForm.serviceRequest.productType = {};
      this.typeSearch = '';
      if (newVal) {
        this.serviceRequestForm.serviceRequest.productType.id = newVal.productTypeId;
        this.serviceRequestForm.serviceRequest.productType.name = newVal.productTypeShoppingName;
      }
    },
    'serviceRequestForm.serviceRequest.insuranceNumber': function(value) {
      const s = /^AC/g;
      const per = '/';
      const twoDig = /^[1-9][0-9]/;
      // console.log('twoDig: ', twoDig.test(value));
      // console.log(s.test(value));
      // console.log('per: ', value.includes(per));
    },
    /* 'attachment.invoice': async function(value) {
      console.log('attachment.invoice: ', value);
      if (value) {
        console.log('van file');
        this.pdfChecker(value, 1);
      }
    },
    'attachment.warrantyCard': async function(value) {
      console.log('attachment.warrantyCard: ', value);
      if (value) {
        console.log('van file');
        this.pdfChecker(value, 2);
      }
    }, */
  }
};
</script>

<style scoped>

</style>
